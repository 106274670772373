import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, Col, Row, message, notification } from "antd";
import { useContext, useState } from "react";
import classes from "./Documents.module.css";

import { FormHeader } from "../FormHeader/FormHeader";
import GlobalContext from "../../contexts/GlobalContext";

function Documents(props) {
  const { prev } = props;
  const { docs, setDocs } = useContext(GlobalContext);

  const [fileList, setFileList] = useState({
    KBIS: docs["KBIS"],
    BAIL: docs["BAIL"],
    PIP: docs["PIP"],
  });

  const handleChange1 = (info, type) => {
    switch (info.file.status) {
      case "uploading":
        type === "KBIS"
          ? setDocs({ ...docs, KBIS: [info.file] })
          : type === "BAIL"
          ? setDocs({ ...docs, BAIL: [info.file] })
          : setDocs({ ...docs, PIP: [info.file] });
        type === "KBIS"
          ? setFileList({ ...fileList, KBIS: [info.file] })
          : type === "BAIL"
          ? setFileList({ ...fileList, BAIL: [info.file] })
          : setFileList({ ...fileList, PIP: [info.file] });

        // setFileList1([info.file]);

        break;
      case "done":
        // setDocs({...docs,type:[info.file]})
        // setFileList({...fileList,type:[info.file]});
        type === "KBIS"
          ? setDocs({ ...docs, KBIS: [info.file] })
          : type === "BAIL"
          ? setDocs({ ...docs, BAIL: [info.file] })
          : setDocs({ ...docs, PIP: [info.file] });
        type === "KBIS"
          ? setFileList({ ...fileList, KBIS: [info.file] })
          : type === "BAIL"
          ? setFileList({ ...fileList, BAIL: [info.file] })
          : setFileList({ ...fileList, PIP: [info.file] });
        break;

      default:
        // error or removed
        type === "KBIS"
          ? setDocs({ ...docs, KBIS: [] })
          : type === "BAIL"
          ? setDocs({ ...docs, BAIL: [] })
          : setDocs({ ...docs, PIP: [] });
        type === "KBIS"
          ? setFileList({ ...fileList, KBIS: [] })
          : type === "BAIL"
          ? setFileList({ ...fileList, BAIL: [] })
          : setFileList({ ...fileList, PIP: [] });
    }
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  return (
    <div>
      <FormHeader title="Documents" number="5" prev={prev} />
      <div className={classes.container}>
        <Row gutter={24} className={classes.displayFlex}>
          {Object.keys(fileList).map((type, index) => {
            return (
              <Col lg={7} md={12} xs={24} key={index}>
                <Upload
                  onChange={(e) => {
                    const fileType = e.file.type;
                    const acceptedTypes = [
                      "image/png",
                      "image/jpeg",
                      "application/pdf",
                    ];

                    if (!acceptedTypes.includes(fileType)) {
                      notification.error({
                        message: "Erreur",
                        duration: 4,
                        closable: true,
                        description: "Type Fichier non valide.",
                      });
                      return false; // Prevent the file from being uploaded
                    } else {
                      handleChange1(e, type);
                    }
                  }}
                  fileList={fileList[type]}
                  customRequest={dummyRequest}
                  accept="image/png, image/jpeg, application/pdf"
                >
                  <Button icon={<UploadOutlined />} size="large" id={type}>
                    {type === "KBIS"
                      ? "Importer “Extrait Kbis”"
                      : type === "BAIL"
                      ? "Importer “Contrat de bail”"
                      : "Importer “Pièce d'identité du représentant”"}
                  </Button>
                </Upload>
              </Col>
            );
          })}
          {/* <Col lg={7} md={12} xs={24} className={classes.centerItems}>
            <Upload
              onChange={handleChange2}
              fileList={fileList2}
              customRequest={dummyRequest}
              accept="image/png, image/jpeg, application/pdf"
            >
              <Button icon={<UploadOutlined />} size="large">
                Importer “Exemplaire bail”
              </Button>
            </Upload>
          </Col>
          <Col lg={7} md={12} xs={24} className={classes.centerItems}>
            <Upload
              onChange={handleChange3}
              fileList={fileList3}
              customRequest={dummyRequest}
              accept="image/png, image/jpeg, application/pdf"
            >
              <Button icon={<UploadOutlined />} size="large">
                Importer “Pièce d'identité présentée”
              </Button>
            </Upload>
          </Col> */}
        </Row>
      </div>
    </div>
  );
}

export default Documents;
