import {
  Affix,
  Col,
  message,
  notification,
  Radio,
  Row,
  Skeleton,
  Spin,
} from "antd";

import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../contexts/GlobalContext";
import { FormHeader } from "../FormHeader/FormHeader";
import GarantieObligatoire from "../GarantieObligatoire/GarantieObligatoire";
import GarantieOption from "../GarantieOption/GarantieOption";
import SliderComponent from "../SliderComponent/SliderComponent";
import classes from "./Tarifications.module.css";
import _isEqual from "lodash/isEqual";
import OptionalWaranty from "../Waranties/OptionalWaranty";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
const garanties_description = {
  Incendie:
    "Dommages aux biens meubles et immeubles (dont les espèces, titres, valeurs, billets et monnaies) liés à l'incendie, au dommage de fumée, la fumée sans incendie, la foudre, l'implosion et l'explosion.",
  TGNA: "Couvre les dommages contenu professionnel (dont espèces, titres, valeurs, billets et monnaies) liés aux évenements climatiques de tempête, de grêle, de neige, etc.",
  CatNat:
    "Cette garantie couvre les dommages causés à votre propriété en cas de catastrophe naturelle telle qu'un incendie, une inondation, un séisme, etc.",
  "Garantie DDE":
    "Suite à ruptures, fuites, infiltrations et débordements pour les dommages au contenu, les frais de recherche et de réparation, les frais de surconsommation d'eau et tout évènement entraînant des dommages d'eau incombant à un tiers identifié.",
  "Garantie BdG":
    "Bris de biens (vitrines, enseignes, plaques, etc), marchandise détériorée par le bris de produits et aménagements en verre, vol de marchandises exposées dans les vitrines suite à leur bris, frais de clôture et de gardiennage.",
  "RC professionnelle":
    "La responsabilité civile est une assurance qui couvre les dommages causés à autrui, par votre entreprise, en lien direct avec l'exercice de l'activité professionnelle, mais également, une fois la prestation ou la vente effectuée.",
  "RC exploitation":
    "L'assurance responsabilité civile exploitation plus permet d'être couvert en cas de dommages corporels, matériels ou immatériels causés à des tiers (clients, fournisseurs...).",
  "Garantie Assistance":
    "Cette garantie vous offre une aide 24 heures sur 24, 7 jours sur 7 en cas de besoin, tels que le remorquage de votre véhicule, l'hébergement en cas d'urgence, etc.",
  "PE suite à incendie ou DDE":
    "Cette garantie vous indemnise pour les pertes financières causées par les dommages subis par votre entreprise en raison d'un incendie ou de dommages d'eau.",
  "PE suite à Vol ou Vandalisme":
    "Elle couvre les pertes financières résultant de dommages causés à votre entreprise en raison d'un vol ou d'un acte de vandalisme.",
  "Perte de Valeur du Fonds":
    "Cette assurance vous indemnise pour la perte de valeur de votre fonds de commerce en cas de dommages subis par votre entreprise.",
  "Garantie Vol":
    "Vol, tentative de vol, vandalisme sur les biens mobiliers assurés et garantis (dont espèces, titres, valeurs, billets et monnaies), détériorations immobilières et coût du remplacement des serrures.",
  "Marchandises en Frigo":
    "Perte des marchandises en installation frigorifiques dont frais annexes de sauvetage.",
  "Garantie Bris de Machines":
    "Bris de machines, matériaux et équipements informatiques ainsi que les frais annexes de reconstituion d'archives.",
  "Garantie Défense Recours":
    "La Garantie Défense Recours couvre les frais juridiques et défend vos intérêts en cas de litige lié à votre assurance.",
  "Garantie Attentat":
    "La Garantie Attentat et actes de terrorisme couvre aussi bien les victimes directes que leurs ayants droits dans les limites de franchise d'assurance .",
  "Dommages Electriques":
    "Dommages à l'installation électrique des bâtiments assurés, aux appareils électriques, électroniques et informatiques (et accessoires) contenus dans les bâtiments assurés.",
  "Extension Extérieurs":
    "Dommages aux terrains, voies d'accès, parkings, clôtures, haies végétales et arbres (dont dessouchage, tronçonnage et évacuation).",
  "Protection Juridique":
    "Elle couvre les coûts liés à un litige découlant de votre activité professionnelle ou de votre entreprise, tels que les frais de justice et les frais de défense.",
  "Marchandises Matériels Transportés":
    "Dommage ou vol de marchandises et instruments professionnels transportés (dont salons, foires et manifestations).",
};

const marksFranchise = {
  300: {
    style: {
      color: "#E30613be",
      marginTop: "10px",
    },
    label: <strong>150 €</strong>,
  },
  800: {
    style: {
      color: "#E30613be",
      marginTop: "10px",
    },
    label: <strong>300 €</strong>,
  },
  1300: {
    style: {
      color: "#E30613be",
      marginTop: "10px",
    },
    label: <strong>400 €</strong>,
  },
  2000: {
    style: {
      color: "#E30613be",
      marginTop: "10px",
    },
    label: <strong>450 €</strong>,
  },
  3000: {
    style: {
      color: "#E30613be",
      marginTop: "10px",
    },
    label: <strong style={{ whiteSpace: "nowrap" }}>1 000 €</strong>,
  },
  4000: {
    style: {
      color: "#E30613be",
      marginTop: "10px",
    },
    label: <strong style={{ whiteSpace: "nowrap" }}>3 000 €</strong>,
  },
  5000: {
    style: {
      color: "#E30613be",
      marginTop: "10px",
    },
    label: <strong style={{ whiteSpace: "nowrap" }}>5 000 €</strong>,
  },
};

const marksCommission = {
  0: {
    style: {
      color: "#E30613be",
      marginTop: "10px",
    },
    label: <strong>0%</strong>,
  },
  2: {
    style: {
      color: "#E30613be",
      marginTop: "10px",
    },
    label: <strong>2%</strong>,
  },
  5: {
    style: {
      color: "#E30613be",
      marginTop: "10px",
    },
    label: <strong>5%</strong>,
  },
  7: {
    style: {
      color: "#E30613be",
      marginTop: "10px",
    },
    label: <strong>7%</strong>,
  },
  10: {
    style: {
      color: "#E30613be",
      marginTop: "10px",
    },
    label: <strong>10%</strong>,
  },
  12: {
    style: {
      color: "#E30613be",
      marginTop: "10px",
    },
    label: <strong>12%</strong>,
  },
  15: {
    style: {
      color: "#E30613be",
      marginTop: "10px",
    },
    label: <strong>15%</strong>,
  },
  17: {
    style: {
      color: "#E30613be",
      marginTop: "10px",
    },
    label: <strong>17%</strong>,
  },
  20: {
    style: {
      color: "#E30613be",
      marginTop: "10px",
    },
    label: <strong>20%</strong>,
  },
  22: {
    style: {
      color: "#E30613be",
      marginTop: "10px",
    },
    label: <strong>22%</strong>,
  },
  25: {
    style: {
      color: "#E30613be",
      marginTop: "10px",
    },
    label: <strong>25%</strong>,
  },
};

function Tarifications(props) {
  const { prev, garantieOptions, setGarantieOptions, form, setDisabled } =
    props;

  const {
    ref,
    axiosInstance,
    globalData,
    prime_commerciale_ttc,
    setPrime_commerciale_ttc,
    garanties_obligatoires,
    setGaranties_obligatoires,
    garanties_facultatives,
    setGaranties_facultatives,
    initGaranties,
    initValues,
    oppDataCompare,
    oppData,
    setOppData,
    partnership_id,
    garantiesAutorise,
    activities,
    activitiesSecondaire,
    current,
    value,
    setTarifRef,
    idRef,
    setIdRef,
    apiToken,
    idUser,
  } = useContext(GlobalContext);

  const [offre, setOffre] = useState("prime_commerciale_ttc");

  const [garantiesloader, setGarantiesloader] = useState(true);
  const [primeloader, setPrimeloader] = useState(true);
  const [loadersArray, setLoadersArray] = useState([]);

  useEffect(() => {
    const payload = {
      code_naf: globalData?.code_naf,
      chiffre_affaire: parseFloat(globalData?.chiffre_affaire),
      commission_tarificateur_autres_gar: globalData?.commission,
      Incendie: garantiesAutorise["Incendie"],
      garanties: {
        "PE suite à incendie ou DDE":
          globalData?.garanties["PE suite à incendie ou DDE"],
        "PE suite à Vol ou Vandalisme":
          globalData?.garanties["PE suite à Vol ou Vandalisme"],
        "Perte de Valeur du Fonds":
          globalData?.garanties["Perte de Valeur du Fonds"],
        "Garantie Vol":
          globalData?.garanties["Garantie Vol"] ||
          garantieOptions["Garantie Vol"],
        "Marchandises en Frigo": globalData?.garanties["Marchandises en Frigo"],
        "Garantie Bris de Machines":
          globalData?.garanties["Garantie Bris de Machines"],
        "Marchandises Matériels Transportés":
          globalData?.garanties["Marchandises Matériels Transportés"],
        "Dommages Electriques": globalData?.garanties["Dommages Electriques"],
        "Extension Extérieurs": globalData?.garanties["Extension Extérieurs"],
        "Protection Juridique": globalData?.garanties["Protection Juridique"],
      },
      franchise: parseFloat(globalData?.franchise),
      code_activite_principale: globalData?.code_activite_principale,
      code_activite_secondaire: globalData?.code_activite_secondaire,
      capitaux_Degat_des_eaux: parseFloat(globalData?.capitaux_Degat_des_eaux),
      capitaux_bris_de_glace: parseFloat(globalData?.capitaux_bris_de_glace),
      surface_locaux: parseFloat(globalData?.surface_locaux),
      valeur_metre_carre: parseFloat(globalData?.valeur_metre_carre),
      contenu_pro: parseFloat(globalData?.contenu_pro),
      capitaux_vol: globalData?.capitaux_vol
        ? parseFloat(globalData?.capitaux_vol)
        : 0,
      capitaux_frigo: globalData?.capitaux_frigo
        ? parseFloat(globalData?.capitaux_frigo)
        : 0,
      capitaux_machine: globalData?.capitaux_machine
        ? parseFloat(globalData?.capitaux_machine)
        : 0,
      capitaux_transport: globalData?.capitaux_transport
        ? parseFloat(globalData?.capitaux_transport)
        : 0,
      capitaux_extension: globalData?.capitaux_extension
        ? parseFloat(globalData?.capitaux_extension)
        : 0,
      capitaux_dommage: globalData?.capitaux_dommage
        ? parseFloat(globalData?.capitaux_dommage)
        : 0,
    };
    setDisabled(true);
    if (JSON.stringify(payload) !== JSON.stringify(initValues)) {
      if (!Object.values(globalData.promises).includes("rejected")) {
        setPrimeloader(true);
        const URL = import.meta.env.VITE_API_URL_AS;
        axiosInstance
          .post(`${URL}/taux_fidelidade/get_prime_commerciale_ttc`, payload)
          .then((res) => {
            setGaranties_obligatoires([
              ...res?.data?.calculate_prime_des_garanties
                ?.garanties_obligatoires,
              {
                "Garantie Défense Recours": {
                  class_risque: null,
                  commission: 0,
                  commission_min: 0,
                  contribution: 0,
                  frais_marge: 0,
                  frais_min: 0,
                  pht: 0,
                  pht_min: 0,
                  prime_pure: 0,
                  prime_pure_applique: 0,
                  prime_pure_min: 0,
                  prime_risque: 0,
                  prime_risque_min: 0,
                  pttc: 0,
                  pttc_mensuel: 0,
                  pttc_min: 0,
                  taux_chargement_prime_risque: 0.25,
                  taux_risque: 12,
                  taxe_min: 0,
                  taxes: 0,
                },
              },
              {
                "Garantie Attentat": {
                  class_risque: null,
                  commission: 6.5,
                  commission_min: 6.5,
                  contribution: 6.5,
                  frais_marge: 6.5,
                  frais_min: 6.5,
                  pht: 6.5,
                  pht_min: 6.5,
                  prime_pure: 6.5,
                  prime_pure_applique: 6.5,
                  prime_pure_min: 6.5,
                  prime_risque: 6.5,
                  prime_risque_min: 6.5,
                  pttc: 6.5,
                  pttc_mensuel: 6.5,
                  pttc_min: 6.5,
                  taux_chargement_prime_risque: 6.5,
                  taux_risque: 12,
                  taxe_min: 6.5,
                  taxes: 6.5,
                },
              },
            ]);
            setGaranties_facultatives(
              res?.data?.calculate_prime_des_garanties?.garanties_facultatives
            );

            setPrime_commerciale_ttc({
              prime_commerciale_ttc:
                res?.data?.get_prime_commerciale_ttc?.prime_commerciale_ttc,
              prime_commerciale_ttc_mensuel:
                res?.data?.get_prime_commerciale_ttc
                  ?.prime_commerciale_ttc_mensuel,
            });

            setTarifRef(res?.data?.tarif_ref);

            let temp = [];

            let i = 0;

            for (
              i = 0;
              i <
              res?.data?.calculate_prime_des_garanties?.garanties_facultatives
                .length;
              i++
            ) {
              temp[i] = false;
            }

            setLoadersArray(temp);
            setPrimeloader(false);

            const geoprod_url = import.meta.env.VITE_API_GEOPROD_URL;
            if (apiToken) {
              let id_user = idUser;

              let cp_souscripteur = form?.getFieldValue("_code_postal");
              let capital_protege = parseFloat(
                form.getFieldValue("capital_protege")
              );
              let adresse_siege = form.getFieldValue("adresse_siege");
              let moyen_protection = form.getFieldValue(
                "Le local est-il à minima, muni des moyens de protection suivants ?"
              );
              let coffre_fort = form.getFieldValue(
                "Détention d'espèces ou objets de valeur de plus de 5 000 Euro ?"
              );
              let NB_sinistres = form.getFieldValue(
                "Nombre de sinistres déclarés au cours des 36 derniers mois :"
              );
              let NB_sinistres_dont_vol = form.getFieldValue(
                "Nombre de sinistres déclarés au cours des 36 derniers mois Dont Vol :"
              );
              const payload = {
                campagne: {
                  choice: false,
                  cycle_vie: "",
                  campagne_id: import.meta.env.VITE_API_CAMPAGNE_ID,
                  product_id: "-1",
                  invalidForms: [],
                },
                details: {
                  etatActuel: "N/R",
                  commentaire: "",
                  formContrat: {
                    cie_text: "",
                    cie_text_id: "",
                    nom_produit: "",
                    pan_ttc: "",
                    pm_ttc: "",
                    nombre_assure: "",
                    date_echeance_principale: "",
                  },
                  tags: [],
                  model_info_complementaires: {},
                  model_info_specifiques: {},
                },
                calendar: {
                  affecter: id_user,
                  affecterLibelle: "",
                  status: 31,
                  statusLibelle: "",
                  parent: -1,
                  dateDebut: "",
                  dateFin: "",
                  invalidForms: [],
                  id_type_opportunity: 4,
                },
                prospect: {
                  invalidForms: [],
                  adress: {
                    streetName: globalData?.adresse_siege,
                    streetNumber: "",
                    ville: globalData?.ville,
                    postalCode: globalData.code_postal,
                  },
                  listProspectsLier: [],
                  mainProspect: {
                    id: "",
                    id_opp: "",
                    campagne_id: "",
                    post: "",
                    interlocuteur: true,
                    ville: globalData?._ville,
                    situation: "Célibataire",
                    users_nom: "",
                    users_prenom: "",
                    adresse_mail: globalData?.souscripteur_email,
                    civilite: "",
                    prospects_civ: "",
                    name: globalData.prenom,
                    surname: globalData.nom,
                    DN: "1999-10-10",
                    prospects_situation: "",
                    nb_enfants: "",
                    adresses_adresse: "",
                    streetNumber: 5,
                    CP: cp_souscripteur,
                    securite_sociale: "",
                    streetName: globalData?._adresse,
                    adresses_ville: "",
                    complement_adr: "140",
                    tel: "",
                    tel2: "",
                    mobile: globalData?.téléphone,
                    first_campagne: "",
                    description: "",
                    etat: "",
                    lat: "",
                    latitude: 36.7025256,
                    longitude: 10.1564818,
                    commercieux_id: "",
                    commentaire: "",
                    geom: "",
                    tags: [],
                    lng: "",
                    date_naiss: "",
                    collab: "",
                    id_pros: "",
                    id_camp: "",
                    coment_plus: "",
                    code: "",
                    rue: "",
                    numero: "",
                    complement: "",
                    mail: "",
                    nb_enfant: "",
                    infcomp: "",
                    relation: "",
                    pays: "",
                    invalidForms: [],
                    deleted: false,
                    information_bancaire: {
                      id: "",
                      titulaire_compte: "",
                      iban: "",
                      bic_swift: "",
                      type_iban: "prestation",
                      nom_banque: "",
                    },
                    prospects_id: "",
                  },
                  information_bancaire: {
                    id: "",
                    titulaire_compte: "",
                    iban: "",
                    bic_swift: "",
                    type_iban: "prestation",
                    nom_banque: "",
                  },
                  id: "",
                  siren: globalData?.siren,
                  form: {
                    data: {
                      rcs: globalData?.rcs,
                      representant: globalData?.representant,
                      en_qualite_de: globalData?.en_qualite_de,
                      code_act_principal: globalData.code_activite_principale,
                      code_act_secondaire: globalData.code_activite_secondaire,
                      code_act_fact: globalData.code_act_fact,
                      chiffre_aff: globalData.chiffre_affaire,
                      adresse_risque:
                        globalData?.adresse_risque || adresse_siege,
                      complement_adresse: globalData?.complement_adresse,
                      qualite_occ: globalData?.QUALITE_OCCUPANT_2,
                      surface_locaux: globalData.surface_locaux,
                      valeur_m_carre: globalData.valeur_metre_carre,
                      contenu_pro: globalData.contenu_pro,
                      capital_protege_total:
                        globalData?.capital_protege || capital_protege,
                      siret: globalData?.NUM_SIRET_1,
                      naf: globalData.code_naf,
                      moyen_protection: moyen_protection || false,
                      coffre_fort: coffre_fort || false,
                      NB_sinistres: NB_sinistres,
                      NB_sinistres_dont_vol: NB_sinistres_dont_vol,
                      ville: globalData?.ville,
                      postalCode: globalData?.code_postal,
                      PAYS: globalData?.Pays,
                      LIBELLE_CODE_NAF: globalData?.label_naf,
                      LIBELLE_ACTIVITE_PRINCIPALE:
                        globalData?.label_activite_principale,
                      LIBELLE_ACTIVITE_SECONDAIRE:
                        globalData?.label_activite_secondaire,
                    },
                    schema: {},
                  },
                  fax: "",
                  form_juridique: globalData?.form_juridique,
                  effectif: 0,
                  FJ: globalData?.form_juridique,
                  mail: "",
                  nom_commercial: "",
                  web: "",
                  tel: "",
                  convention_collective: "",
                  respensable: "",
                  tranche_effectifs: "",
                  numero_tva_intra: "",
                  date_creation: globalData?.date_creation
                    ? dayjs(globalData?.date_creation)?.format("YYYY-MM-DD")
                    : null,
                  streetNumber: "",
                  streetName: "",
                  adresses_adresse: globalData?.adresse_siege,
                  ville: globalData?.ville,
                  type_opportunite: "entrepise",
                  typeProspect: "entrepise",
                  listProspects: [],
                  RS: globalData?.raison_sociale,
                },
              };
              let token = apiToken;
              let id_opp = sessionStorage.getItem("id_opp");
              if (!_isEqual(oppData, oppDataCompare) || !id_opp) {
                axiosInstance
                  .post(`${geoprod_url}insertopportunite`, payload, {
                    headers: {
                      idSession: token,
                    },
                  })
                  .then((response) => {
                    sessionStorage.setItem(
                      "id_opp",
                      response?.data?.id_opportunite_md5
                    );
                    sessionStorage.setItem(
                      "id_pros",
                      response?.data?.id_prospect_md5
                    );
                    setOppData({ ...oppDataCompare });
                    const data = {
                      globalData: globalData,
                      id_opp: response?.data?.id_opportunite_md5,
                      garantiesAutorise: garantiesAutorise,
                      prime_commerciale_ttc: {
                        prime_commerciale_ttc:
                          res?.data?.get_prime_commerciale_ttc
                            ?.prime_commerciale_ttc,
                        prime_commerciale_ttc_mensuel:
                          res?.data?.get_prime_commerciale_ttc
                            ?.prime_commerciale_ttc_mensuel,
                      },
                      activities: activities,
                      activitiesSecondaire: activitiesSecondaire,
                      value: value,
                      garanties_facultatives:
                        res?.data?.calculate_prime_des_garanties
                          ?.garanties_facultatives,
                      garanties_obligatoires: [
                        ...res?.data?.calculate_prime_des_garanties
                          ?.garanties_obligatoires,
                        {
                          "Garantie Défense Recours": {
                            class_risque: null,
                            commission: 0,
                            commission_min: 0,
                            contribution: 0,
                            frais_marge: 0,
                            frais_min: 0,
                            pht: 0,
                            pht_min: 0,
                            prime_pure: 0,
                            prime_pure_applique: 0,
                            prime_pure_min: 0,
                            prime_risque: 0,
                            prime_risque_min: 0,
                            pttc: 0,
                            pttc_mensuel: 0,
                            pttc_min: 0,
                            taux_chargement_prime_risque: 0.25,
                            taux_risque: 12,
                            taxe_min: 0,
                            taxes: 0,
                          },
                        },
                        {
                          "Garantie Attentat": {
                            class_risque: null,
                            commission: 6.5,
                            commission_min: 6.5,
                            contribution: 6.5,
                            frais_marge: 6.5,
                            frais_min: 6.5,
                            pht: 6.5,
                            pht_min: 6.5,
                            prime_pure: 6.5,
                            prime_pure_applique: 6.5,
                            prime_pure_min: 6.5,
                            prime_risque: 6.5,
                            prime_risque_min: 6.5,
                            pttc: 6.5,
                            pttc_mensuel: 6.5,
                            pttc_min: 6.5,
                            taux_chargement_prime_risque: 6.5,
                            taux_risque: 12,
                            taxe_min: 6.5,
                            taxes: 6.5,
                          },
                        },
                      ],
                      submited_date: dayjs().format("DD/MM/YYYY"),
                      step: current,
                    };
                    const dumpJson = JSON.stringify(data);
                    const id_ref = uuidv4();
                    setIdRef(id_ref);

                    axiosInstance
                      .post(
                        `${
                          import.meta.env.VITE_API_URL_AS
                        }/session_url/set_object_from_session_url`,
                        {
                          objet: dumpJson,
                          id: id_ref,
                        }
                      )
                      .then((responseSession) => {
                        axiosInstance
                          .post(
                            `${
                              import.meta.env.VITE_API_URL_AS
                            }/historique_communications/add`,
                            {
                              id_opp: response?.data?.id_opportunite_md5,
                              url:
                                window.location.origin +
                                "/?partnership_id=" +
                                partnership_id +
                                "&ref=" +
                                id_ref,
                            },
                            {
                              headers: {
                                apiKey: localStorage.getItem("api_key"),
                              },
                            }
                          )
                          .then((res) => {
                            setGarantiesloader(false);
                            setDisabled(false);
                          })
                          .catch(() => {
                            setDisabled(false);
                          });
                      })
                      .catch((error) => {
                        setDisabled(false);
                        notification.error({
                          message: "Erreur",
                          duration: 4,
                          closable: true,
                          description: error?.response?.data?.message,
                        });
                      });
                  })
                  .catch((err) => {
                    setDisabled(false);
                    return err;
                  });
              } else {
                const data = {
                  globalData: globalData,
                  id_opp: sessionStorage.getItem("id_opp"),
                  garantiesAutorise: garantiesAutorise,
                  prime_commerciale_ttc: {
                    prime_commerciale_ttc:
                      res?.data?.get_prime_commerciale_ttc
                        ?.prime_commerciale_ttc,
                    prime_commerciale_ttc_mensuel:
                      res?.data?.get_prime_commerciale_ttc
                        ?.prime_commerciale_ttc_mensuel,
                  },
                  activities: activities,
                  activitiesSecondaire: activitiesSecondaire,
                  value: value,
                  garanties_facultatives:
                    res?.data?.calculate_prime_des_garanties
                      ?.garanties_facultatives,
                  garanties_obligatoires: [
                    ...res?.data?.calculate_prime_des_garanties
                      ?.garanties_obligatoires,
                    {
                      "Garantie Défense Recours": {
                        class_risque: null,
                        commission: 0,
                        commission_min: 0,
                        contribution: 0,
                        frais_marge: 0,
                        frais_min: 0,
                        pht: 0,
                        pht_min: 0,
                        prime_pure: 0,
                        prime_pure_applique: 0,
                        prime_pure_min: 0,
                        prime_risque: 0,
                        prime_risque_min: 0,
                        pttc: 0,
                        pttc_mensuel: 0,
                        pttc_min: 0,
                        taux_chargement_prime_risque: 0.25,
                        taux_risque: 12,
                        taxe_min: 0,
                        taxes: 0,
                      },
                    },
                    {
                      "Garantie Attentat": {
                        class_risque: null,
                        commission: 6.5,
                        commission_min: 6.5,
                        contribution: 6.5,
                        frais_marge: 6.5,
                        frais_min: 6.5,
                        pht: 6.5,
                        pht_min: 6.5,
                        prime_pure: 6.5,
                        prime_pure_applique: 6.5,
                        prime_pure_min: 6.5,
                        prime_risque: 6.5,
                        prime_risque_min: 6.5,
                        pttc: 6.5,
                        pttc_mensuel: 6.5,
                        pttc_min: 6.5,
                        taux_chargement_prime_risque: 6.5,
                        taux_risque: 12,
                        taxe_min: 6.5,
                        taxes: 6.5,
                      },
                    },
                  ],
                  submited_date: dayjs().format("DD/MM/YYYY"),
                  step: current,
                };
                const dumpJson = JSON.stringify(data);
                axiosInstance
                  .post(
                    `${
                      import.meta.env.VITE_API_URL_AS
                    }/session_url/set_object_from_session_url`,
                    {
                      objet: dumpJson,
                      id: idRef,
                    }
                  )
                  .then((responseSession) => {
                    setGarantiesloader(false);

                    setDisabled(false);
                  });
              }
            } else {
              notification.error({
                message: "Erreur",
                duration: 4,
                closable: true,
                description: "Erreur de connexion",
              });
            }
          })
          .catch((err) => {
            setGarantiesloader(false);
            setDisabled(false);
          });
      }
    } else {
      if (ref) {
        let temp = [];

        let i = 0;

        for (i = 0; i < garanties_facultatives.length; i++) {
          temp[i] = false;
        }
        setGaranties_facultatives(initGaranties?.garanties_facultatives);
        setGaranties_obligatoires(initGaranties?.garanties_obligatoires);
        setPrime_commerciale_ttc(initGaranties?.prime_commerciale_ttc);
        setLoadersArray(temp);
        setGarantiesloader(false);
        setPrimeloader(false);
        setDisabled(false);
      }
    }
  }, [globalData]);

  const onChangeOffre = ({ target: { value } }) => {
    setOffre(value);
  };

  const convertFranchise = (franchise) => {
    switch (franchise) {
      case 0:
        return 0;
      case 150:
        return 300;
      case 300:
        return 800;
      case 400:
        return 1300;
      case 450:
        return 2000;
      case 1000:
        return 3000;
      case 3000:
        return 4000;
      case 5000:
        return 5000;
      default:
        return 1300;
    }
  };

  return (
    <div className={classes.container}>
      <FormHeader
        title="Tarifications"
        number="4"
        prev={garantiesloader ? null : prev}
      />

      {garantiesloader ? (
        <Spin size="large" />
      ) : (
        <>
          <div className={classes.container}>
            <Affix offsetTop={0}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                className={classes.tarifContainer}
              >
                {primeloader || garantiesloader ? (
                  <h1 style={{ marginTop: "-1rem", marginBottom: "2rem" }}>
                    <Skeleton.Input
                      active={true}
                      size={"default"}
                      block={false}
                    />
                  </h1>
                ) : (
                  <h1
                    style={{
                      color: "#E30613",
                      marginTop: "-1rem",
                      marginBottom: "2rem",
                    }}
                  >
                    {prime_commerciale_ttc[offre]?.toLocaleString("fr-FR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    € TTC{" "}
                    <span style={{ fontSize: 9 }}>(Hors Frais Dossier)</span>
                  </h1>
                )}
                <div
                  style={{
                    marginTop: "-1rem",
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Radio.Group
                    onChange={onChangeOffre}
                    defaultValue={"prime_commerciale_ttc"}
                    size="large"
                    buttonStyle="solid"
                  >
                    <Radio.Button
                      id="offre_mensuel"
                      value="prime_commerciale_ttc_mensuel"
                    >
                      Cotisation Mensuelle
                    </Radio.Button>
                    <Radio.Button
                      id="offre_annuel"
                      value="prime_commerciale_ttc"
                    >
                      Cotisation Annuelle
                    </Radio.Button>
                  </Radio.Group>
                </div>
              </div>
            </Affix>
            <h4 className={classes.sectionheader}>Garanties Obligatoires</h4>

            <div className={classes.requiredWaranties}>
              {garanties_obligatoires?.map((garantie, idx) => {
                return (
                  <GarantieObligatoire
                    key={idx}
                    title={Object.keys(garantie)[0]}
                    classe_risque={
                      garantie[Object.keys(garantie)[0]]?.class_risque == "R"
                        ? 0
                        : garantie[Object.keys(garantie)[0]]?.class_risque
                    }
                    desc={garanties_description[Object.keys(garantie)[0]]}
                    /*  value={Number(
                      garantie[Object.keys(garantie)[0]]?.pttc
                    ).toFixed(2)} */
                    value={
                      offre === "prime_commerciale_ttc_mensuel"
                        ? garantie[
                            Object.keys(garantie)[0]
                          ]?.pttc_mensuel.toLocaleString("fr-FR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : garantie[
                            Object.keys(garantie)[0]
                          ]?.pttc.toLocaleString("fr-FR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                    }
                  />
                );
              })}
              {garanties_obligatoires?.length === 0 && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Spin />
                </div>
              )}
            </div>
          </div>
          <div className={classes.containerFluid}>
            <h4 className={classes.sectionheader}>Garanties Optionnelles</h4>

            <Row gutter={24} className={classes.cardsContainer}>
              {garanties_facultatives?.map((garantie, idx) => {
                return (
                  <GarantieOption
                    key={idx}
                    idx={idx}
                    loadersArray={loadersArray}
                    setLoadersArray={setLoadersArray}
                    title={Object.keys(garantie)[0]}
                    classe_risque={
                      !globalData[
                        "Le local est-il à minima, muni des moyens de protection suivants ?"
                      ] && Object.keys(garantie)[0] === "Garantie Vol"
                        ? 0
                        : garantie[Object.keys(garantie)[0]]?.class_risque == 8
                        ? 0
                        : garantie[Object.keys(garantie)[0]]?.class_risque
                    }
                    form={form}
                    desc={garanties_description[Object.keys(garantie)[0]]}
                    garantieOptions={garantieOptions}
                    setGarantieOptions={setGarantieOptions}
                    fieldName={Object.keys(garantie)[0]}
                    value={
                      !globalData[
                        "Le local est-il à minima, muni des moyens de protection suivants ?"
                      ] && Object.keys(garantie)[0] === "Garantie Vol"
                        ? "0"
                        : offre === "prime_commerciale_ttc_mensuel"
                        ? garantie[
                            Object.keys(garantie)[0]
                          ]?.pttc_mensuel.toLocaleString("fr-FR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : garantie[
                            Object.keys(garantie)[0]
                          ]?.pttc.toLocaleString("fr-FR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                    }
                    offre={offre}
                  />
                );
              })}
              {garanties_facultatives?.length === 0 && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Spin />
                </div>
              )}
            </Row>
          </div>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              margin: "2.5rem",
            }}
          >
            {primeloader ? (
              <Spin size="large" style={{ margin: "2rem" }} />
            ) : (
              <h1 style={{ color: "#E30613", margin: "2rem" }}>
                {" "}
                {prime_commerciale_ttc[offre].toFixed(2)} €
              </h1>
            )}

            <div
              style={{
                marginTop: "-1rem",
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Radio.Group
                onChange={onChangeOffre}
                defaultValue={"prime_commerciale_ttc"}
                size="large"
                buttonStyle="solid"
              >
                <Radio.Button value="prime_commerciale_ttc_mensuel">
                  Offre Mensuelle
                </Radio.Button>
                <Radio.Button value="prime_commerciale_ttc">
                  Offre Annuelle
                </Radio.Button>
              </Radio.Group>
            </div>
          </div> */}
          <div className={classes.footerBlock}>
            <h4>Franchise</h4>
            <p style={{ marginTop: "-10px" }}>
              Un montant minimum à payer en cas de sinistre couvert par votre
              assurance, qui est déduit de l'indemnité versée par l'assureur.
            </p>
            <div
              className={classes.sliderContainer}
              style={{
                marginTop: "-5px",
              }}
            >
              <SliderComponent
                marks={marksFranchise}
                defValue={convertFranchise(globalData.franchise)}
                max={5000}
                min={300}
                fieldName="franchise"
              />
            </div>
          </div>
          <div className={classes.footerBlock} style={{ marginTop: "2rem" }}>
            <h4>Commission</h4>
            <p style={{ marginTop: "-10px" }}>
              Taux de commission appliqué au contrat :
            </p>
            <div
              className={classes.sliderContainer}
              style={{
                marginTop: "-5px",
              }}
            >
              <SliderComponent
                marks={marksCommission}
                defValue={globalData.commission}
                max={25}
                min={0}
                fieldName="commission"
              />
            </div>
          </div>
          <div style={{ width: "64%" }}>
            <Row gutter={23} justify={"center"}>
              <Col lg={12}>
                <OptionalWaranty
                  {...{
                    borderColor: "#EEEEEE",
                    available: true,
                    inputVisible: true,
                    switchDefaultValue: true,
                    title: "Frais de dossier courtier",
                    description: null,
                    inputOptions: {
                      name: "frais_dossier",
                      form,
                      min: 0,
                      max: 500,
                      label: null,
                      tip: "Frais de dossier compris entre 0 € et 500 €",
                      placeHolder: "Max 500",
                    },
                  }}
                />
              </Col>
              <Col lg={12}>
                <OptionalWaranty
                  {...{
                    borderColor: "#EEEEEE",
                    available: true,
                    inputVisible: true,
                    switchDefaultValue: false,
                    title: "Frais de dossier AsSolutions",
                    description: null,
                    inputOptions: {
                      name: "frais_dossier_as",
                      form: form,
                      min: 10,
                      max: 50,
                      label: null,
                      placeHolder: "10% frais de dossier courtier",
                    },
                  }}
                />
              </Col>
            </Row>
          </div>
          <div style={{ width: "64%" }}>
            <h4>
              La première cotisation sera de{" "}
              <span style={{ color: "#E32714" }}>
                {primeloader || garantiesloader ? (
                  <Skeleton.Input active={true} size={"small"} block={false} />
                ) : (
                  (
                    prime_commerciale_ttc["prime_commerciale_ttc_mensuel"] +
                    (form.getFieldValue("frais_dossier") || 0) +
                    (form.getFieldsValue()["frais_dossier"] / 10 > 10
                      ? form.getFieldsValue()["frais_dossier"] / 10
                      : 10)
                  )?.toLocaleString("fr-FR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + " € TTC"
                )}
              </span>
            </h4>
          </div>
        </>
      )}
    </div>
  );
}

export default Tarifications;
