import { Typography } from "antd";

import React from "react";
import InputNumberSlider from "../InputNumberSlider";
import WarantyTag from "./WarantyTag";

const RequiredWaranty = ({
  title,
  description,
  available,
  inputVisible,
  inputOptions,
}) => {
  return (
    <div
      style={{
        backgroundColor: available ? "white" : "#FFE8E6",
        border: "3px solid #E00000",
        borderRadius: 13,
        height: "100%",
        padding: "0 10px",
      }}
    >
      <Typography.Title level={4} style={{ textAlign: "center" }}>
        {title}
      </Typography.Title>
      <Typography.Paragraph style={{ textAlign: "center" }}>
        {description}
      </Typography.Paragraph>
      <div
        style={{
          margin: "10px 0",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <WarantyTag available={available} />
      </div>
      {available && inputVisible && (
        <div style={{ width: "80%", margin: "0 auto" }}>
          <InputNumberSlider {...inputOptions} />
        </div>
      )}
    </div>
  );
};

export default RequiredWaranty;
