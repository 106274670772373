import { Checkbox, Col, Form, Input, Radio, Row, Spin, Switch } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { FormHeader } from "../FormHeader/FormHeader";
import sepa from "../../assets/sepa.png";
import checked from "../../assets/checked.png";
import unchecked from "../../assets/unchecked.png";
import classes from "./InformationBancaire.module.css";
import dollar from "../../assets/svg/dollar.svg";
import GlobalContext from "../../contexts/GlobalContext";
import axios from "axios";
import CustomLoader from "../CustomLoader/CustomLoader";
function InformationBancaire(props) {
  const [sameBanqueAccount, setSameBanqueAccount] = useState(false);
  const { prev, form } = props;
  const {
    payementType,
    setPayementType,
    typesFrac,
    setTypesFrac,
    axiosInstance,
    apiToken,
  } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const forms = ["Nom de la banque", "Titulaire du compte", "BIC", "IBAN"];

  const InputsPattern = (input) => {
    switch (input) {
      case "IBAN":
        // return /^AL\d{10}[0-9A-Z]{16}$|^AD\d{10}[0-9A-Z]{12}$|^AT\d{18}$|^BH\d{2}[A-Z]{4}[0-9A-Z]{14}$|^BE\d{14}$|^BA\d{18}$|^BG\d{2}[A-Z]{4}\d{6}[0-9A-Z]{8}$|^HR\d{19}$|^CY\d{10}[0-9A-Z]{16}$|^CZ\d{22}$|^DK\d{16}$|^FO\d{16}$|^GL\d{16}$|^DO\d{2}[0-9A-Z]{4}\d{20}$|^EE\d{18}$|^FI\d{16}$|^FR\d{12}[0-9A-Z]{11}\d{2}$|^GE\d{2}[A-Z]{2}\d{16}$|^DE\d{20}$|^GI\d{2}[A-Z]{4}[0-9A-Z]{15}$|^GR\d{9}[0-9A-Z]{16}$|^HU\d{26}$|^IS\d{24}$|^IE\d{2}[A-Z]{4}\d{14}$|^IL\d{21}$|^IT\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^[A-Z]{2}\d{5}[0-9A-Z]{13}$|^KW\d{2}[A-Z]{4}22!$|^LV\d{2}[A-Z]{4}[0-9A-Z]{13}$|^LB\d{6}[0-9A-Z]{20}$|^LI\d{7}[0-9A-Z]{12}$|^LT\d{18}$|^LU\d{5}[0-9A-Z]{13}$|^MK\d{5}[0-9A-Z]{10}\d{2}$|^MT\d{2}[A-Z]{4}\d{5}[0-9A-Z]{18}$|^MR13\d{23}$|^MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}$|^MC\d{12}[0-9A-Z]{11}\d{2}$|^ME\d{20}$|^NL\d{2}[A-Z]{4}\d{10}$|^NO\d{13}$|^PL\d{10}[0-9A-Z]{,16}n$|^PT\d{23}$|^RO\d{2}[A-Z]{4}[0-9A-Z]{16}$|^SM\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^SA\d{4}[0-9A-Z]{18}$|^RS\d{20}$|^SK\d{22}$|^SI\d{17}$|^ES\d{22}$|^SE\d{22}$|^CH\d{7}[0-9A-Z]{12}$|^TN59\d{20}$|^TR\d{7}[0-9A-Z]{17}$|^AE\d{21}$|^GB\d{2}[A-Z]{4}\d{14}$/;
        // return /^[A-Z\s]{2}[0-9\s]{2}[A-Z0-9\s]{1,27}$/;
        return /^[A-Z\s]{2}[0-9\s]{2}[A-Z0-9\s]{1,27}$/;
      case "BIC":
        // return /([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)/;
        // return /^[A-Z\s]{6}[A-Z0-9\s]{2}([A-Z0-9\s]{3})?$/;
        return /^[A-Z\s]{6}[A-Z0-9\s]{2}([A-Z0-9\s]{3})?$/
      default:
        return "";
    }
  };
  const validRegex = (value, pattern) => {
    const trimmedValue = value ? value.replace(/\s/g, "") : "";
    const regex = new RegExp(pattern);
    return regex.test(trimmedValue);
  };

  const handleChangeValues = (e, key) => {
    if (sameBanqueAccount) {
      form.setFieldValue(key, e.target.value);
    }
  };

  useEffect(() => {
    axiosInstance
      .get(`${import.meta.env.VITE_API_URL_AS}/v1/fractionnement`, {
        headers: {
          idSession: apiToken,
        },
      })
      .then((res) => {
        setLoading(false);
        setTypesFrac(res?.data?.fractionnements);
        form.setFieldsValue({
          Fractionnement: res?.data?.fractionnements.find(
            (e) => e.libelle === "Mensuel"
          )?.id,
        });
      })
      .catch((e) => setLoading(false));
  }, []);

  return (
    <div>
      <FormHeader title="Paiement" number="6" prev={prev} />
      <div
        className={classes.cards}
        style={{ marginTop: "2rem", marginBottom: "2rem" }}
      >
        <div
          lg={12}
          xs={24}
          className={classes.column}
          onClick={() => setPayementType("Direct Debit")}
        >
          <div className={classes.card}>
            <img src={sepa} />
            <span className={classes.typepayement}>Mandat SEPA</span>
          </div>
          {payementType == "Direct Debit" ? (
            <img
              src={checked}
              style={{ height: "1rem", width: "1rem", cursor: "pointer" }}
            />
          ) : (
            <img
              src={unchecked}
              style={{ height: "1rem", width: "1rem", cursor: "pointer" }}
            />
          )}
        </div>
      </div>
      {payementType == "Direct Debit" && (
        <Row className={classes.container}>
          <Col className={classes.periodCard}>
            {loading ? (
              <Spin size="large" />
            ) : (
              <Form.Item
                name={"Fractionnement"}
                initialValue={
                  typesFrac.find((e) => e.libelle === "Mensuel")?.id
                }
              >
                <Radio.Group
                  value={form.getFieldValue()["Fractionnement"]}
                  className={classes.formGroup}
                >
                  {typesFrac?.map((type, index) => (
                    <Radio
                      key={index}
                      className={classes.radioText}
                      value={type?.id}
                      id={type?.id}
                    >
                      Fractionnement {type?.libelle}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            )}
          </Col>
          <Col span={18} className={classes.Card}>
            <h1 className={classes.prelev}>
              {" "}
              <img style={{ marginRight: "0.5rem" }} src={dollar} alt="" />
              Prélèvement
            </h1>
            {forms.map((form, index) => (
              <Form.Item
                key={index}
                name={form + " prev"}
                label={<label className={classes.label}>{form}</label>}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    validator: (_, value) => {
                      const emptyValue = value ? value.replace(/\s/g, "") : "";
                      if (!emptyValue) {
                        return Promise.reject(
                          new Error("Veuillez remplir ce champ.")
                        );
                      }
                      if (value) {
                        if (!validRegex(value, InputsPattern(form))) {
                          return Promise.reject(
                            new Error("Veuillez vérifier ce champ.")
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  controls={false}
                  size="large"
                  placeholder={"Votre " + form}
                  onChange={(e) => handleChangeValues(e, form + " remb")}
                  style={{ padding: "10px 10px", width: "100%" }}
                />
              </Form.Item>
            ))}

            <div className={classes.jourPrev}>
              <Form.Item
                name={"Jour de prélèvement"}
                label={
                  <label className={classes.label}>Jour de prélèvement *</label>
                }
              >
                <Radio.Group
                  value={form.getFieldsValue()["Jour de prélèvement"]}
                >
                  <Radio style={{ margin: "0px 20px" }} value={5} id="5">
                    <div style={{ height: "100%", marginTop: "5px" }}>5</div>
                  </Radio>
                  <Radio style={{ margin: "0px 20px" }} value={10} id="10">
                    <div style={{ height: "100%", marginTop: "5px" }}>10</div>
                  </Radio>
                  <Radio style={{ margin: "0px 20px" }} value={15} id="15">
                    <div style={{ height: "100%", marginTop: "5px" }}>15</div>
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </Col>

          <Col span={18} className={classes.Card}>
            <h1 className={classes.prelev}>
              <img style={{ marginRight: "0.5rem" }} src={dollar} alt="" />
              Remboursement
            </h1>
            <div style={{ marginBottom: "15px" }}>
              <Checkbox
                checked={sameBanqueAccount}
                onChange={(e) => {
                  setSameBanqueAccount(e.target.checked);

                  if (e.target.checked) {
                    form.setFieldValue(
                      "Nom de la banque remb",
                      form.getFieldsValue()["Nom de la banque prev"]
                    );
                    form.setFieldValue(
                      "Titulaire du compte remb",
                      form.getFieldsValue()["Titulaire du compte prev"]
                    );
                    form.setFieldValue(
                      "BIC remb",
                      form.getFieldsValue()["BIC prev"]
                    );
                    form.setFieldValue(
                      "IBAN remb",
                      form.getFieldsValue()["IBAN prev"]
                    );
                  } else {
                    form.setFieldValue("Nom de la banque remb", "");
                    form.setFieldValue("Titulaire du compte remb", "");
                    form.setFieldValue("BIC remb", "");
                    form.setFieldValue("IBAN remb", "");
                  }
                }}
              >
                <p style={{ textAlign: "left" }}>
                  Si le compte de remboursement est le même que le compte de
                  prélèvement,
                  <br /> cochez cette case.
                </p>
              </Checkbox>
            </div>
            {forms.map((form, index) => (
              <Form.Item
                key={index}
                name={form + " remb"}
                label={<label className={classes.label}>{form}</label>}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    validator: (_, value) => {
                      const emptyValue = value ? value.replace(/\s/g, "") : "";
                      if (!emptyValue) {
                        return Promise.reject(
                          new Error("Veuillez remplir ce champ.")
                        );
                      }
                      if (value) {
                        if (!validRegex(value, InputsPattern(form))) {
                          return Promise.reject(
                            new Error("Veuillez vérifier ce champ.")
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  controls={false}
                  size="large"
                  disabled={sameBanqueAccount}
                  placeholder={"Votre " + form}
                  style={{ padding: "10px 10px", width: "100%" }}
                />
              </Form.Item>
            ))}
          </Col>
        </Row>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "50%" }}>
          <Form.Item
            rules={[
              { required: true, message: "Veuillez choisir une option." },
            ]}
            label={
              <h3 style={{ margin: "0 5px" }}>
                J’accepte de recevoir les offres commerciales personnalisées
                distribuées par mon courtier.
              </h3>
            }
            style={{ padding: "0 30px" }}
            name={
              "J’accepte de recevoir les offres commerciales personnalisées distribuées par mon courtier."
            }
          >
            <Radio.Group size="small">
              <Radio value={true} id="Oui">
                <div style={{ fontSize: "18px" }}>Oui</div>
              </Radio>
              <Radio value={false} id="Non">
                <div style={{ fontSize: "18px" }}>Non</div>
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
    </div>
  );
}

export default InformationBancaire;
