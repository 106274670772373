import "./index.css";
import "dayjs/locale/fr";
import {version} from "../package.json"
import dayjs from "dayjs";
import frFR from "antd/locale/fr_FR";
import { Layout } from 'antd';
const {  Footer } = Layout;
import { ConfigProvider } from "antd";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import AppContext from "./contexts/AppContext";

import * as Sentry from "@sentry/react";
import Home from "./pages/Home/Home";
import Success from "./pages/Home/Success";
import ExpirationPage from "./pages/ExpirationPage";
import VisiteRisque from "./pages/Home/VisiteRisque";
import NotFound from "./components/NotFound/NotFound";
dayjs.locale("fr");

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/visite_de_risque",
    element: <VisiteRisque />,
  },
  {
    path: "/success/:id",
    element: <Success />,
  },
  {
    path: "/expired",
    element: <ExpirationPage />,
  },
  {
    path: "/*",
    element: <NotFound />,
  },
]);


if (import.meta.env.VITE_API_ENVIRONEMENT === 'production') {
  Sentry.init({
    dsn: 'https://caa9482e0de1579a443c3c4ce33fec59@sentry.neopolis-dev.com/29',
    tracesSampleRate: 1.0,
  });
}

const footerStyle = {
  textAlign: 'center',
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <AppContext>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#e30613",
        },
        components: {
          Steps: {
            fontSizeLG: 14,
          },

          Input: {
            colorTextDisabled: "rgb(0, 0, 0)",
          },

          Divider: {
            colorSplit: "rgb(227, 6, 19)",
            colorText: "rgb(227, 6, 19)",
            fontSize: 24,
            lineWidth: 3,
          },
        },
      }}
      locale={frFR}
    >
      <RouterProvider router={router} />
      <Footer style={footerStyle} > V {version} - {import.meta.env.VITE_API_TAG} </Footer>
    </ConfigProvider>
  </AppContext>
);
