import { Slider } from "antd";
import { useContext, useEffect, useState } from "react";
import GlobalContext from "../../contexts/GlobalContext";

function SliderComponent(props) {
  const { globalData, setGlobalData } = useContext(GlobalContext);
  const { marks, max, min, defValue, fieldName } = props;
  const [franchise, setFranchise] = useState(defValue);
  useEffect(() => {
    if (fieldName === "franchise") {
      switch (defValue) {
        case 0:
          setFranchise(0);
          break;
        case 150:
          setFranchise(300);
          break;
        case 300:
          setFranchise(800);
          break;
        case 400:
          setFranchise(1300);
          break;
        case 500:
          setFranchise(2000);
          break;
        case 1000:
          setFranchise(3000);
          break;
        case 3000:
          setFranchise(4000);
          break;
        case 5000:
          setFranchise(5000);
          break;
      }
    }
  }, [defValue]);
  const onChange = (value) => {
    if (fieldName === "franchise") {
      let val = 0;
      switch (value) {
        case 0:
          val = 0;
          break;
        case 300:
          val = 150;
          break;
        case 800:
          val = 300;
          break;
        case 1300:
          val = 400;
          break;
        case 2000:
          val = 450;
          break;
        case 3000:
          val = 1000;
          break;
        case 4000:
          val = 3000;
          break;
        case 5000:
          val = 5000;
          break;
      }
      setGlobalData({ ...globalData, franchise: val });
    } else {
      setGlobalData({ ...globalData, commission: value });
    }
  };
  return (
    <div id={fieldName}>
      <Slider
        onAfterChange={onChange}
        min={min}
        max={max}
        step={null}
        marks={marks}
        defaultValue={fieldName === "franchise" ? franchise : defValue}
        tooltip={{ open: false }}
        id="slider_component"
      />
    </div>
  );
}

export default SliderComponent;
