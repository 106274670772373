import {
  Checkbox,
  Col,
  ConfigProvider,
  Form,
  InputNumber,
  notification,
  Row,
  Switch,
  Timeline,
  Tooltip,
} from "antd";
import React, { useState } from "react";
import { FormHeader } from "../FormHeader/FormHeader";
import classes from "./DevoirDeConseil.module.css";

import PopoverInfo from "./PopoverInfo";
import { useContext } from "react";
import GlobalContext from "../../contexts/GlobalContext";
import { InfoCircleOutlined } from "@ant-design/icons";

function DevoirDeConseil(props) {
  const { prev, form, garantieOptions, setGarantieOptions } = props;
  const { warantyVol, setWarantyVol, setGlobalData, globalData } =
    useContext(GlobalContext);

  const [exam, setExam] = useState({
    "Avez-vous fait l’objet d’une résiliation pour non-paiement de cotisation par un précédent assureur au cours des 36 derniers mois ?": false,
    "Avez-vous fait l’objet d’une résiliation pour sinistres par un précédent assureur au cours des 36 derniers mois ?": false,
    "Avez-vous fait l’objet d’une résiliation pour fausse déclaration (ou nullité du contrat) par un précédent assureur au cours des 36 derniers mois ?": false,
    "Êtes-vous sous le coup d’une liquidation ou d’un redressement judiciaire ?": false,
    "Le local et les dépendances sont-ils construits et couvert à moins de 75% en matériaux durs ?": false,
    "Le bâtiment dans lequel le local se situe est-il inoccupé ou désaffecté ?  ": false,
    "Le sommet des marchandises entreposées excède-t-il une hauteur de 7.20 mètres prise à partir du sol ?  ": false,
    "Le local est-il classé ou inscrit au titre des Monuments historiques, ou situés dans de tels bâtiments ?": false,
    "Le local est-il situé dans un centre commercial dont la superficie est supérieure à 3000 m2 ?  ": false,

    Discothèque: form.getFieldsValue(true)["Discothèque"] || false,
    "Boîte de nuit": form.getFieldsValue(true)["Boîte de nuit"] || false,
    Bowling: form.getFieldsValue(true)["Bowling"] || false,
    Cabaret: form.getFieldsValue(true)["Cabaret"] || false,
    "Bar de nuit": form.getFieldsValue(true)["Bar de nuit"] || false,
    "Tout autre établissement uniquement ouvert la nuit":
      form.getFieldsValue(true)[
        "Tout autre établissement uniquement ouvert la nuit"
      ] || false,

    "Application de peintures et de vernis":
      form.getFieldsValue(true)["Application de peintures et de vernis"] ||
      false,
    "Transformation de matières plastiques alvéolaires":
      form.getFieldsValue(true)[
        "Transformation de matières plastiques alvéolaires"
      ] || false,
    "des stocks d’emballages combustibles vides pour un montant supérieur à 15 000 € ou 30 000 € commerce de gros.":
      form.getFieldsValue(true)[
        "des stocks d’emballages combustibles vides pour un montant supérieur à 15 000 € ou 30 000 € commerce de gros."
      ] || false,
  });

  const table = [
    {
      Input: "Nombre de sinistres déclarés au cours des 36 derniers mois :",
    },
    {
      Input:
        "Nombre de sinistres déclarés au cours des 36 derniers mois Dont Vol :",
    },
    {
      YesOrNoQuestion:
        "Avez-vous fait l’objet d’une résiliation pour non-paiement de cotisation par un précédent assureur au cours des 36 derniers mois ?",
    },
    {
      YesOrNoQuestion:
        "Avez-vous fait l’objet d’une résiliation pour sinistres par un précédent assureur au cours des 36 derniers mois ?",
    },
    {
      YesOrNoQuestion:
        "Avez-vous fait l’objet d’une résiliation pour fausse déclaration (ou nullité du contrat) par un précédent assureur au cours des 36 derniers mois ?",
    },
    {
      YesOrNoQuestion:
        "Êtes-vous sous le coup d’une liquidation ou d’un redressement judiciaire ?",
      Title: "Le local à assurer",
    },
    {
      YesOrNoQuestion:
        "Le contenu comprend-il plus de 500 litres de liquides inflammable ?",
    },
    {
      YesOrNoQuestion:
        "Le volume total des chambres froides (en une ou plusieurs chambres) est-il supérieur à 1500 m3 ?",
    },
    {
      YesOrNoQuestion: "Le local est-il frappé d’alignement ?",
    },
    {
      YesOrNoQuestion:
        "Le local est-il situé dans un bâtiment construit sur le terrain d’autrui ?",
    },
    {
      YesOrNoQuestion:
        "Le local comporte-t-il une terrasse vitrée ou des vitrines à panneaux mobiles avancées sur le trottoir ou sur une place ?",
    },
    {
      YesOrNoQuestion:
        "Le local a-t-il une utilisation principale de dépôt, d’entrepôt ou de réserve ?",
    },
    {
      YesOrNoQuestion: "Le local se trouve-t-il dans une zone industrielle ?",
    },
    {
      YesOrNoQuestion:
        "Le local se trouve-t-il à plus de 300 mètres de bâtiments d’habitation régulièrement habités ?",
    },
    {
      YesOrNoQuestion:
        "Le local et les dépendances sont-ils construits et couvert à moins de 75% en matériaux durs ?",
    },
    {
      YesOrNoQuestion:
        "Le local est-il à minima, muni des moyens de protection suivants ?",
    },
    {
      YesOrNoQuestion:
        "Le bâtiment dans lequel le local se situe est-il inoccupé ou désaffecté ?  ",
    },
    {
      MultipleAnswers: "Le local est-il situé dans un bâtiment renfermant:",
      propositions: {
        rs: [
          "Discothèque",
          "Boîte de nuit",
          "Bowling",
          "Cabaret",
          "Bar de nuit",
          "Tout autre établissement uniquement ouvert la nuit",
        ],
        as: [
          "Application de peintures et de vernis",
          "Transformation de matières plastiques alvéolaires",
        ],
        YesOrNoQuestion:
          "Des stocks de plus de 5 m3 d’emballages vides en matières plastiques alvéolaires ou stock d’emballages combustibles vides pour un montant supérieur à 15.000€ ou 30.000€ pour les commerces de gros",
      },
    },
    {
      YesOrNoQuestion:
        "Le sommet des marchandises entreposées excède-t-il une hauteur de 7.20 mètres prise à partir du sol ?  ",
    },
    {
      YesOrNoQuestion:
        "Le local est-il classé ou inscrit au titre des Monuments historiques, ou situés dans de tels bâtiments ?",
    },
    {
      YesOrNoQuestion: "Le local est –t-il un centre commercial ?",
    },

    {
      YesOrNoQuestion:
        "Pour les établissements recevant du public (ERP) : je reconnais que le minimum requis au titre de la « sécurité des personnes » est respecté.",
    },
  ];

  return (
    <div>
      <FormHeader
        title="Éléments de validation du risque"
        number="2"
        prev={prev}
      />
      <Row gutter={24} className={classes.rowContainer}>
        <Col md={20}>
          <h2>Antécédents d’assurance</h2>
        </Col>
      </Row>
      {table.map((element, index) => {
        if (element?.YesOrNoQuestion) {
          return (
            <ConfigProvider
              theme={{
                components: {
                  Form: {
                    margin: 0,
                    marginLG: 0,
                    marginXS: 0,
                  },
                },
              }}
              key={index}
            >
              <Row gutter={24} className={classes.rowContainer} key={index}>
                <Col
                  md={16}
                  xs={24}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h4>
                    {element.YesOrNoQuestion}
                    {element.YesOrNoQuestion ===
                      "Avez-vous fait l’objet d’une résiliation pour fausse déclaration (ou nullité du contrat) par un précédent assureur au cours des 36 derniers mois ?" && (
                      <Tooltip
                        title={
                          "Nous n’acceptons pas les entreprises ayant fait l’objet d’une résiliation pour fausse déclaration"
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    )}
                    {element.YesOrNoQuestion ===
                      "Le contenu comprend-il plus de 500 litres de liquides inflammable ?" && (
                      <Tooltip
                        title={
                          "C’est-à-dire dont le point d’éclair est inférieur à 55 °C et/ou leur équivalent en gaz liquéfiés (toute bouteille étant comptée pour 25 litres), quelle que soit leur utilisation autre que le chauffage des locaux"
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    )}
                    {element.YesOrNoQuestion ===
                      "Êtes-vous sous le coup d’une liquidation ou d’un redressement judiciaire ?" && (
                      <Tooltip
                        title={
                          "Nous n’acceptons pas les entreprises ayant fait l’objet d’un redressement ou d’une liquidation judiciaire"
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    )}
                    {element.YesOrNoQuestion ===
                      "Le local et les dépendances sont-ils construits et couvert à moins de 75% en matériaux durs ?" && (
                      <Tooltip
                        title={
                          <span>
                            Pour les murs extérieurs : béton, briques, pierres
                            ou parpaings unis par un liant, vitrages, panneaux
                            simples ou doubles de métal ou fibre-ciment ou en
                            panneaux composites constitués d’un isolant minéral
                            pris en sandwich entre deux plaques de métal ou
                            fibre-ciment <br />
                            Couverture des locaux :ardoises, tuiles, vitrages,
                            plaques simples de métal ou fibre-ciment, panneaux
                            composites constitués d’un isolant minéral pris en
                            sandwich entre deux plaques de métal ou fibre-ciment
                            ou en béton avec revêtement d’étanchéité
                          </span>
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    )}
                    {element.YesOrNoQuestion ===
                      "Le bâtiment dans lequel le local se situe est-il inoccupé ou désaffecté ?  " && (
                      <Tooltip
                        title={
                          "Sont considérés comme désaffectés, les locaux qui en raison de la durée de leur inoccupation et de leur non-entretien, ne peuvent être utilisés en l’état et nécessitent, pour remplir leur fonction, des travaux importants. Il s’agit de locaux fermés et sans possibilité d’utilisation (ouvertures condamnées), ou occupés par des personnes non autorisées par l’Assuré (squatters, vagabonds...), de locaux voués à la démolition ou destinés à être réhabilités, de locaux pour lesquels un arrêté de péril, d’insalubrité, ou portant interdiction d’habiter a été pris par les autorités compétentes."
                        }
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    )}
                    {element?.YesOrNoQuestion ===
                      "Le local est-il à minima, muni des moyens de protection suivants ?" && (
                      <span id="moyen_protection_tooltip">
                        <PopoverInfo />
                      </span>
                    )}
                    <br />
                    {element?.YesOrNoQuestion ===
                      "Le local est-il à minima, muni des moyens de protection suivants ?" && (
                      <div
                        style={{
                          fontWeight: 500,
                          fontSize: 11,
                          color: "#7b4343",
                        }}
                      >
                        En cas d’absence de moyens de protections, la garantie
                        vol ne sera pas acquise.
                      </div>
                    )}
                  </h4>
                </Col>

                <Col lg={4} md={12} xs={24}>
                  <Form.Item
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (
                            _.field ===
                              "Le local est-il à minima, muni des moyens de protection suivants ?" ||
                            _.field ===
                              "Le volume total des chambres froides (en une ou plusieurs chambres) est-il supérieur à 1500 m3 ?"
                          ) {
                            return Promise.resolve();
                          }
                          if (
                            _.field ===
                              "Pour les établissements recevant du public (ERP) : je reconnais que le minimum requis au titre de la « sécurité des personnes » est respecté." &&
                            value === true
                          ) {
                            return Promise.resolve();
                          } else if (
                            _.field ===
                              "Pour les établissements recevant du public (ERP) : je reconnais que le minimum requis au titre de la « sécurité des personnes » est respecté." &&
                            value === false
                          ) {
                            notification.destroy();
                            notification.error({
                              message: "Erreur",
                              duration: 4,
                              closable: true,
                              description:
                                "Pardon, vous ne pouvez pas souscrire un contrat MRP.",
                            });
                            return Promise.reject(
                              new Error("Veuillez vérifier ce champ.")
                            );
                          } else if (
                            _.field !==
                              "Le local est-il à minima, muni des moyens de protection suivants ?" &&
                            _.field !==
                              "Pour les établissements recevant du public (ERP) : je reconnais que le minimum requis au titre de la « sécurité des personnes » est respecté." &&
                            value !== true
                          ) {
                            return Promise.resolve();
                          } else {
                            notification.destroy();
                            notification.error({
                              message: "Erreur",
                              duration: 4,
                              closable: true,
                              description:
                                "Pardon, vous ne pouvez pas souscrire un contrat MRP.",
                            });
                            return Promise.reject(
                              new Error("Veuillez vérifier ce champ.")
                            );
                          }
                        },
                      }),
                    ]}
                    name={element?.YesOrNoQuestion}
                    id={element?.YesOrNoQuestion}
                    valuePropName="checked"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Switch
                      size="default"
                      checkedChildren={"Oui"}
                      unCheckedChildren={"Non"}
                      defaultChecked={
                        element?.YesOrNoQuestion ===
                          "Pour les établissements recevant du public (ERP) : je reconnais que le minimum requis au titre de la « sécurité des personnes » est respecté." ||
                        form.getFieldValue(element?.YesOrNoQuestion)
                      }
                      onChange={(value) => {
                        if (
                          element?.YesOrNoQuestion ===
                          "Le local est-il à minima, muni des moyens de protection suivants ?"
                        ) {
                          setGlobalData({
                            ...globalData,
                            "Le local est-il à minima, muni des moyens de protection suivants ?":
                              value,
                          });
                          setWarantyVol(value);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                {element?.Title && (
                  <Col md={20}>
                    <h2>{element?.Title}</h2>
                  </Col>
                )}
              </Row>
            </ConfigProvider>
          );
        } else if (element?.Input) {
          return (
            <Row gutter={24} className={classes.rowContainer} key={index}>
              <Col md={16} xs={24}>
                <h4>
                  {element.Input}{" "}
                  <Tooltip
                    title={
                      "Nous acceptons 2 sinistres maximum dont 1 sinistre Vol maximum"
                    }
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </h4>
              </Col>
              <Col lg={4} md={12} xs={24}>
                <Form.Item
                  name={element?.Input}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value == null) {
                          return Promise.reject(
                            new Error("Veuillez remplir ce champ.")
                          );
                        } else if (
                          0 <= value &&
                          value < 2 &&
                          value <=
                            form.getFieldsValue()[
                              "Nombre de sinistres déclarés au cours des 36 derniers mois :"
                            ] &&
                          value < 2 &&
                          element?.Input.includes("Vol")
                        ) {
                          return Promise.resolve();
                        } else if (
                          0 <= value &&
                          value <= 2 &&
                          !element?.Input.includes("Vol")
                        ) {
                          return Promise.resolve();
                        }
                        notification.destroy();
                        notification.error({
                          message: "Erreur",
                          duration: 4,
                          closable: true,
                          description:
                            "Pardon, vous ne pouvez pas souscrire un contrat MRP.",
                        });
                        return Promise.reject(
                          new Error("Veuillez vérifier ce champ.")
                        );
                      },
                    }),
                  ]}
                  // validateTrigger="onBlur"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <InputNumber
                    style={{ width: "50%" }}
                    maxLength={3}
                    controls={false}
                    onKeyPress={(event) => {
                      if (event.key === "." || event.key === ",") {
                        event.preventDefault();
                      } else if (event.which < 48 || event.which > 57) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          );
        } else {
          return (
            <div className={classes.container} key={index}>
              <h3 style={{ marginLeft: "2.9rem", color: "black" }}>
                {element?.MultipleAnswers}
              </h3>
              <Timeline style={{ marginLeft: "3.9rem" }}>
                <Timeline.Item>
                  <h4 style={{ fontWeight: "bold", marginBottom: "-1px" }}>
                    Les risques suivants :
                  </h4>
                  <Row
                    gutter={24}
                    className={classes.propBox}
                    justify={"center"}
                  >
                    {element?.propositions?.rs.map((select, index) => {
                      return (
                        <Col
                          lg={"auto"}
                          key={index}
                          style={{
                            height: "1.5rem",
                            padding: "0 3rem",
                            margin: "0 0",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Form.Item name={select}>
                            <Checkbox
                              name={select}
                              onChange={(e) => {
                                form.setFieldsValue({
                                  ...form.getFieldsValue(),
                                  [e.target.name]:
                                    e.target.checked !== false
                                      ? e.target.checked
                                      : undefined,
                                });
                                setExam({
                                  ...exam,
                                  [e.target.name]:
                                    e.target.checked !== false
                                      ? e.target.checked
                                      : undefined,
                                });

                                if (e.target.checked) {
                                  notification.destroy();
                                  notification.error({
                                    message: "Erreur",
                                    duration: 4,
                                    closable: true,
                                    description:
                                      "Pardon, vous ne pouvez pas souscrire un contrat MRP.",
                                  });
                                } else {
                                  notification.destroy();
                                }
                              }}
                              checked={exam[select]}
                            >
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 600,
                                }}
                              >
                                {select}
                              </span>
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      );
                    })}
                  </Row>
                </Timeline.Item>
                <Timeline.Item>
                  <h4
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      marginBottom: "-1px",
                      marginTop: 0,
                    }}
                  >
                    Les activités suivantes :
                  </h4>
                  <Row
                    gutter={24}
                    className={classes.propBox}
                    justify={"center"}
                  >
                    {element?.propositions?.as.map((select, index) => {
                      return (
                        <Col
                          key={index}
                          lg={12}
                          style={{
                            height: "1.5rem",
                            padding: "0 3rem",
                            margin: ".3rem 0",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Form.Item name={select}>
                            <Checkbox
                              name={select}
                              onChange={(e) => {
                                form.setFieldsValue({
                                  ...form.getFieldsValue(),
                                  [e.target.name]:
                                    e.target.checked !== false
                                      ? e.target.checked
                                      : undefined,
                                });
                                setExam({
                                  ...exam,
                                  [e.target.name]:
                                    e.target.checked !== false
                                      ? e.target.checked
                                      : undefined,
                                });

                                if (e.target.checked) {
                                  notification.destroy();
                                  notification.error({
                                    message: "Erreur",
                                    duration: 4,
                                    closable: true,
                                    description:
                                      "Pardon, vous ne pouvez pas souscrire un contrat MRP.",
                                  });
                                } else {
                                  notification.destroy();
                                }
                              }}
                              checked={exam[select]}
                            >
                              <span
                                style={{
                                  fontWeight: 600,
                                  fontSize: "12px",
                                  // textTransform: "capitalize",
                                }}
                              >
                                {select}
                              </span>
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      );
                    })}
                  </Row>
                </Timeline.Item>
                <Timeline.Item>
                  <ConfigProvider
                    theme={{
                      components: {
                        Form: {
                          margin: 0,
                          marginLG: 0,
                          marginXS: 0,
                        },
                      },
                    }}
                  >
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                      key={index}
                    >
                      <Col
                        lg={20}
                        md={12}
                        xs={24}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <h4 style={{ marginTop: "2px" }}>
                          {element?.propositions?.YesOrNoQuestion}{" "}
                        </h4>
                      </Col>
                      <Col
                        lg={4}
                        md={12}
                        xs={24}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingLeft: "0rem",
                        }}
                      >
                        <Form.Item
                          name={element?.propositions?.YesOrNoQuestion}
                          valuePropName="checked"
                        >
                          <Switch
                            size="default"
                            checkedChildren={"Oui"}
                            unCheckedChildren={"Non"}
                            defaultChecked={form.getFieldValue(
                              element?.propositions?.YesOrNoQuestion
                            )}
                            onChange={(e) => {
                              if (e) {
                                notification.destroy();
                                notification.error({
                                  message: "Erreur",
                                  duration: 4,
                                  closable: true,
                                  description:
                                    "Pardon, vous ne pouvez pas souscrire un contrat MRP.",
                                });
                              } else {
                                notification.destroy();
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </ConfigProvider>
                </Timeline.Item>
              </Timeline>
            </div>
          );
        }
      })}
    </div>
  );
}

export default DevoirDeConseil;
