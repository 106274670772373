import { Form, Slider, InputNumber as AntdInputNumber, Typography } from "antd";
import React, { useEffect, useState } from "react";
import GlobalContext from "../contexts/GlobalContext";

const InputNumberSlider = ({
  form,
  name,
  label,
  min,
  max,
  tip,
  placeHolder,
  disabled,
}) => {
  const { globalData, setGlobalData } = React.useContext(GlobalContext);
  const [inputValue, setInputValue] = useState(
    form?.getFieldsValue()[name] || null
  );

  const handleChangeValue = (newValue) => {
    setInputValue(newValue);
    if (name === "frais_dossier") {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        frais_dossier: newValue,
        frais_dossier_as: newValue / 10 < 10 ? 10 : newValue / 10,
      });
      setGlobalData({
        ...globalData,
        frais_dossier: newValue,
        frais_dossier_as: newValue / 10 < 10 ? 10 : newValue / 10,
      });
    } else {
      form.setFieldsValue({ ...form.getFieldsValue(), [name]: newValue });
    }
  };

  useEffect(() => {
    if (min === max) {
      form.setFieldsValue({ ...form.getFieldsValue(), [name]: min });
      setInputValue(min);
    } else {
      setInputValue(form?.getFieldsValue()[name]);
    }
  }, []);
  return (
    <>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: !disabled,
            message: "Veuillez remplir ce champ.",
          },
          () => ({
            validator(_, value) {
              if (!disabled)
                if (value || value === 0)
                  if ((value > max || value < min) && min !== max) {
                    return Promise.reject(
                      new Error(
                        `La valeur doit être comprise entre ${min} et ${max}`
                      )
                    );
                  }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <AntdInputNumber
          disabled={disabled || min === max}
          controls={false}
          size="large"
          style={{ width: "100%" }}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
          parser={(value) => value.replace(/\$\s?|( )/g, "")}
          addonAfter={"€"}
          addonBefore={
            <span
              style={{
                color: "red",
                fontSize: 18,
              }}
            >
              *
            </span>
          }
          placeholder={placeHolder}
          onChange={(newValue) => handleChangeValue(newValue)}
          onKeyPress={(event) => {
            if (event.which < 48 || event.which > 57) {
              event.preventDefault();
            }
          }}
        />
      </Form.Item>
      {min !== max && (
        <div id={name + "_slider"}>
          <Form.Item name={name}>
            <Slider
              tooltip={{
                formatter: (value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " "),
              }}
              disabled={disabled}
              onAfterChange={handleChangeValue}
              value={inputValue || min}
              min={min}
              max={max}
            />
          </Form.Item>
        </div>
      )}
      {tip && (
        <Typography.Paragraph
          style={{
            fontSize: 11,
            color: "#0B68FA",
            fontStyle: "italic",
            fontWeight: 500,
          }}
        >
          {tip}
        </Typography.Paragraph>
      )}
    </>
  );
};

export default InputNumberSlider;
