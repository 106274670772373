import {
  Form,
  Input,
  Col,
  Row,
  Select,
  InputNumber,
  DatePicker,
  notification,
  AutoComplete,
  Space,
  Tooltip,
  Switch,
  ConfigProvider,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState, useContext } from "react";
import {
  PhoneOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import debounce from "debounce";

import classes from "./InfoPrincipale.module.css";
import { FormHeader } from "../FormHeader/FormHeader";
import SectionHeader from "./SectionHeader/SectionHeader";

import GlobalContext from "../../contexts/GlobalContext";

import Societe from "../../assets/Societe.png";
import BreifCase from "../../assets/briefcase.png";
import Location from "../../assets/location.png";
import Souscripteur from "../../assets/souscripteur.png";
import frFR from "antd/locale/fr_FR";
import PhoneInput, { locale } from "antd-phone-input";

const handleLegalUnitRCS = (data) => {
  const siren = data?.unite_legale?.siren;
  const libelleCommune =
    data?.unite_legale?.etablissement_siege?.libelle_commune;
  const rcs = siren + " R.C.S " + libelleCommune;
  return siren && libelleCommune ? rcs : "";
};

const handleLegalUnitSiret = (data, siret) => {
  const legalUnit = data?.unite_legale;

  if (
    !legalUnit ||
    !legalUnit.etablissements ||
    !legalUnit.etablissement_siege
  ) {
    return null;
  }
  const etablissements = Array.from(
    new Set([
      ...(legalUnit?.etablissements || []),
      legalUnit?.etablissement_siege,
    ])
  );

  const selectedEtablissement = etablissements.find(
    (elem) => elem?.siret === legalUnit?.siren?.toString() + siret
  );

  if (!selectedEtablissement) return null;

  let num_voie = selectedEtablissement?.numero_voie
    ? selectedEtablissement?.numero_voie
    : "";
  let type_voie = selectedEtablissement?.type_voie
    ? selectedEtablissement?.type_voie
    : "";
  let libelle_voie = selectedEtablissement?.libelle_voie
    ? selectedEtablissement?.libelle_voie
    : "";

  const address = {
    date_creation: dayjs(selectedEtablissement?.date_creation),
    adresse_siege: `${num_voie} ` + `${type_voie} ` + `${libelle_voie} `,
    code_postal: selectedEtablissement?.code_postal,
    code_naf:
      selectedEtablissement.activite_principale.split(".")[0] +
      selectedEtablissement.activite_principale.split(".")[1],
  };

  return address;
};

const handleGetLegalUnitSiretOptions = (data) => {
  const legalUnit = data?.unite_legale;
  if (
    !legalUnit ||
    !legalUnit.etablissements ||
    !legalUnit.etablissement_siege
  ) {
    return null;
  }
  const etablissements = [
    ...legalUnit?.etablissements,
    legalUnit?.etablissement_siege,
  ];

  const uniqueEtablissements = Array.from(
    new Set(etablissements.map((elem) => elem?.siret))
  ).map((siret) => {
    return {
      value: siret,
      label: siret.slice(-5),
    };
  });

  return uniqueEtablissements;
};

const form_juridique_options = [
  {
    label: "Entreprise individuelle",
    value: "Entreprise individuelle",
  },
  {
    label: "Micro-entreprise",
    value: "Micro-entreprise",
  },
  {
    label: "SASU",
    value: "SASU",
  },
  {
    label: "EURL",
    value: "EURL",
  },
  {
    label: "SCI",
    value: "SCI",
  },
  {
    label: "Association",
    value: "Association",
  },
  {
    label: "Sociétés à responsabilité limitée (SARL)",
    value: "Sociétés à responsabilité limitée (SARL)",
  },
  {
    label: "Sociétés par actions simplifiées (SAS)",
    value: "Sociétés par actions simplifiées (SAS)",
  },
  {
    label: "Sociétés anonymes (SA)",
    value: "Sociétés anonymes (SA)",
  },
  {
    label: "Sociétés en commandite simple (SCS)",
    value: "Sociétés en commandite simple (SCS)",
  },
  {
    label: "Sociétés en commandite par actions (SCA)",
    value: "Sociétés en commandite par actions (SCA)",
  },
  {
    label: "Sociétés civiles",
    value: "Sociétés civiles",
  },
  {
    label: "Sociétés coopératives",
    value: "Sociétés coopératives",
  },
  {
    label: "Entreprises publiques locales (EPL)",
    value: "Entreprises publiques locales (EPL)",
  },
];

const validator = (_, { valid }) => {
  // if (valid(true)) return Promise.resolve(); // strict validation
  if (valid()) return Promise.resolve(); // non-strict validation
  return Promise.reject("Invalid phone number");
};
function InfoPrincipale(props) {
  const {
    formeJuridique,
    setFormeJuridique,
    activities,
    setActivities,
    activitiesSecondaire,
    setActivitiesSecondaire,
    globalData,
    setGlobalData,
    setGarantiesAutorise,
    axiosInstance,
    apiToken,
  } = useContext(GlobalContext);
  const { form, setGarantieOptions } = props;
  const [villes, setVilles] = useState([]);
  const [_villes, set_Villse] = useState([]);
  const [labelNaf, setLabelNaf] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [sirenData, setSirenData] = useState(
    JSON.parse(sessionStorage.getItem("sirenData")) ?? {}
  );
  const [siretOptions, setSiretOptions] = useState([]);

  const URL_MRP = import.meta.env.VITE_API_URL_AS;

  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      date_creation: JSON.parse(sessionStorage.getItem("globalData"))
        .date_creation
        ? dayjs(JSON.parse(sessionStorage.getItem("globalData")).date_creation)
        : null,
    });
    axiosInstance
      .get(`${URL_MRP}/code_naf/liste_all_code_activite`)
      .then((res) => {
        setActivitiesSecondaire(res.data?.codes_activites);
      })
      .catch((err) => err);
    axiosInstance
      .get(`${URL_MRP}/v1/forme_juridique`, {
        headers: {
          idSession: apiToken,
        },
      })
      .then((res) => {
        let forme_juridique_options = [];
        res.data.results.forEach((option) => {
          forme_juridique_options.push({
            value: option?.id,
            label: option?.libelle,
          });
        });
        setFormeJuridique(forme_juridique_options);
      })
      .catch((err) => err);
  }, []);

  useEffect(() => {
    if (form.getFieldsValue()["siren"]) {
      sessionStorage.setItem("sirenData", JSON.stringify(sirenData));
      const temp = handleGetLegalUnitSiretOptions(sirenData);
      setSiretOptions(temp);
    }
  }, [sirenData]);

  const dateFormatList = "DD/MM/YYYY";

  const get_code_activities = (code_naf) => {
    if (code_naf) {
      axiosInstance
        .get(`${URL_MRP}/code_naf/liste_code_activite?code_naf=${code_naf}`)
        .then((res) => {
          if (res?.data?.codes_activites.length == 0) {
            notification.error({
              message: "Erreur",
              duration: 4,
              closable: true,
              description:
                "Nous sommes désolés ! Notre produit n'est pas adapté à l'activité renseignée.",
            });
          }

          if (activities.length != 0) {
            setActivities([]);
            form.setFieldsValue({
              ...form.getFieldsValue(),
              code_activite_principale: "",
            });
          }

          let codes_activites = res?.data?.codes_activites;
          let prepare_activities = [];
          codes_activites.forEach((code) => {
            if (code.value && code.label) {
              prepare_activities.push({
                value: code.value,
                label: `${code.label}`,
              });
            }
          });
          if (prepare_activities.length == 1) {
            setLabelNaf(res?.data?.codes_activites[0]?.label_naf);
            form.setFieldsValue({
              ...form.getFieldsValue(),
              label_naf: res?.data?.codes_activites[0]?.label_naf,
              code_activite_principale: codes_activites[0].value,
              label_activite_principale: codes_activites[0].label,
            });
            setGlobalData({
              ...globalData,
              label_naf: res?.data?.codes_activites[0]?.label_naf,
              code_activite_principale: codes_activites[0].value,
              label_activite_principale: codes_activites[0].label,
            });
          } else {
            if (code_naf !== globalData?.code_naf) {
              setLabelNaf(res?.data?.codes_activites[0]?.label_naf);
              form.setFieldsValue({
                ...form.getFieldsValue(),
                label_naf: res?.data?.codes_activites[0]?.label_naf,
                code_activite_principale: codes_activites[0].value,
                label_activite_principale: codes_activites[0].label,
              });
              setGlobalData({
                ...globalData,
                label_naf: res?.data?.codes_activites[0]?.label_naf,
                code_activite_principale: codes_activites[0].value,
                label_activite_principale: codes_activites[0].label,
              });
            } else {
              setLabelNaf(
                globalData.label_naf || res?.data?.codes_activites[0]?.label_naf
              );
              form.setFieldsValue({
                ...form.getFieldsValue(),
                label_naf:
                  globalData.label_naf ||
                  res?.data?.codes_activites[0]?.label_naf,
                code_activite_principale:
                  form.getFieldsValue().code_activite_principale ||
                  codes_activites[0].value,
                label_activite_principale:
                  form.getFieldsValue().label_activite_principale ||
                  codes_activites[0].label,
              });
              setGlobalData({
                ...globalData,
                label_naf:
                  globalData.label_naf ||
                  res?.data?.codes_activites[0]?.label_naf,
                code_activite_principale:
                  form.getFieldsValue().code_activite_principale ||
                  codes_activites[0].value,
                label_activite_principale:
                  form.getFieldsValue().label_activite_principale ||
                  codes_activites[0].label,
              });
            }
          }
          setActivities(prepare_activities);
        })
        .catch((err) => err);
    }
  };

  const fillInputs = (res) => {
    let code_naf =
      res.data.unite_legale.activite_principale.split(".")[0] +
      res.data.unite_legale.activite_principale.split(".")[1];
    let num_voie = res.data.unite_legale.etablissement_siege?.numero_voie
      ? res.data.unite_legale.etablissement_siege?.numero_voie
      : "";
    let type_voie = res.data.unite_legale.etablissement_siege?.type_voie
      ? res.data.unite_legale.etablissement_siege?.type_voie
      : "";
    let libelle_voie = res.data.unite_legale.etablissement_siege?.libelle_voie
      ? res.data.unite_legale.etablissement_siege?.libelle_voie
      : "";
    const data = {
      raison_sociale: res?.data?.unite_legale?.denomination,
      code_naf: code_naf || "",
      code_postal: res?.data?.unite_legale?.etablissement_siege?.code_postal,
      adresse_siege: `${num_voie} ` + `${type_voie} ` + `${libelle_voie}`,
      code_activite_principale: "",
      NUM_SIRET_1: res?.data?.unite_legale?.etablissement_siege?.siret,
      rcs: handleLegalUnitRCS(res?.data),
      date_creation: dayjs(
        res.data.unite_legale.etablissement_siege.date_creation
      ),
    };
    sessionStorage.setItem(
      "siret",
      res?.data?.unite_legale?.etablissement_siege?.siret
        ? res?.data?.unite_legale?.etablissement_siege?.siret
        : ""
    );
    sessionStorage.setItem(
      "entreprise_creation_date",
      res?.data?.unite_legale?.date_creation
        ? dayjs(res?.data?.unite_legale?.date_creation).format("DD/MM/YYYY")
        : ""
    );
    form.setFieldsValue({
      ...form.getFieldsValue(),
      ...data,
    });
    setGlobalData({
      ...globalData,
      ...data,
      NUM_SIRET_1: res?.data?.unite_legale?.etablissement_siege?.siret
        ? res?.data?.unite_legale?.etablissement_siege?.siret
        : "",
    });
    get_code_activities(code_naf);
    getVilles({
      target: {
        value: res?.data?.unite_legale?.etablissement_siege?.code_postal,
      },
    });
    setDisabled(false);
  };

  const fillSiretInput = (siret) => {
    const res = handleLegalUnitSiret(sirenData, siret);

    if (res) {
      sessionStorage.setItem("siret", siret);
      form.setFieldsValue({
        ...form.getFieldsValue(),
        ...res,
      });
      setGlobalData({
        ...globalData,
        ...res,
      });
      get_code_activities(res?.code_naf);
      getVilles({
        target: {
          value: res?.code_postal,
        },
      });
    }
  };

  const validRegex = (value, pattern) => {
    const trimmedValue = value ? value.replace(/\s/g, "") : "";
    const regex = new RegExp(pattern);
    return regex.test(trimmedValue);
  };

  const get_garanties_autorise = () => {
    let code_naf = form.getFieldValue()["code_naf"];
    let code_activite_principale =
      form.getFieldValue()["code_activite_principale"];
    let code_activite_secondaire =
      form.getFieldValue()["code_activite_secondaire"];
    let chiffre_affaire = form.getFieldValue()["chiffre_affaire"];

    if (code_naf && code_activite_principale && chiffre_affaire) {
      axiosInstance
        .post(`${URL_MRP}/taux_fidelidade/get_garanties_autorise`, {
          code_naf: code_naf,
          code_activite_principale: code_activite_principale,
          code_activite_secondaire: code_activite_secondaire,
          chiffre_affaire: chiffre_affaire,
        })

        .then((response) => {
          setGarantiesAutorise(response.data?.garanties);
          setGarantieOptions(response.data?.garanties);
          setGlobalData({
            ...globalData,
            garanties: {
              "Dommages Electriques":
                response.data?.garanties["Dommages Electriques"],
              "Extension Extérieurs":
                response.data?.garanties["Extension Extérieurs"],
              "Garantie Bris de Machines":
                response.data?.garanties["Garantie Bris de Machines"],
              "Garantie Vol": response.data?.garanties["Garantie Vol"],
              "Marchandises en Frigo":
                response.data?.garanties["Marchandises en Frigo"],
              "Marchandises Matériels Transportés":
                response.data?.garanties["Marchandises Matériels Transportés"],
              "PE suite à Vol ou Vandalisme":
                response.data?.garanties["PE suite à Vol ou Vandalisme"],
              "PE suite à incendie ou DDE":
                response.data?.garanties["PE suite à incendie ou DDE"],
              "Perte de Valeur du Fonds":
                response.data?.garanties["Perte de Valeur du Fonds"],
              "Protection Juridique":
                response.data?.garanties["Protection Juridique"],
            },
            label_activite_secondaire:
              form.getFieldValue()["label_activite_secondaire"],
          });
        })
        .catch((err) => err);
    }
  };

  const onSearch = (value) => {
    if (value.target.value?.length >= 9) {
      setDisabled(true);

      const URL_NEO =
        import.meta.env.VITE_API_NEO_URL_SIREN + value.target.value;

      axiosInstance
        .get(URL_NEO)
        .then((res) => {
          setSirenData(res?.data);
          fillInputs(res);
          setDisabled(false);
        })
        .catch((err) => {
          fillInputs("");
          if (!err?.response?.data?.message) {
            notification.error({
              message: "Erreur",
              duration: 4,
              closable: true,
              description:
                "Service de récupération automatique des informations de société est momentanément indisponible !",
            });
          }
          setDisabled(false);
        });
    }
  };

  const get_villes_by_code_postal = (code_postal) => {
    if (code_postal.length === 5) {
      const URL = import.meta.env.VITE_API_URL_AS;
      axiosInstance
        .post(`${URL}/groupe_villes/get_villes_by_code_postal`, {
          postal_code: code_postal,
        })
        .then((res) => {
          if (res.data?.villes) {
            let ville_names = [];
            res.data.villes.forEach((ville) => {
              ville_names.push({
                value: ville?.nom_comm,
                label: ville?.nom_comm,
              });
            });
            set_Villse(ville_names);
            form.setFieldsValue({
              ...form.getFieldsValue(),
              _ville: form.getFieldsValue()["_ville"]
                ? ville_names?.find(
                    (elem) => elem?.value === form.getFieldsValue()["_ville"]
                  )
                  ? form.getFieldsValue()["_ville"]
                  : ville_names[0]?.value
                : ville_names[0]?.value,
            });
          } else {
            set_Villse([]);
            form.setFieldsValue({
              ...form.getFieldsValue(),
              _ville: "",
            });
          }
        })
        .catch((err) => {
          set_Villse([]);
          form.setFieldsValue({
            ...form.getFieldsValue(),
            _ville: "",
          });
        });
    } else {
      set_Villse([]);
      form.setFieldsValue({
        ...form.getFieldsValue(),
        _ville: "",
      });
    }
  };

  const disabledDate = (current) => {
    const currentDate = dayjs();
    const minDate = currentDate;
    const maxDate = currentDate.add(6, "month");
    return current && (current < minDate || current > maxDate);
  };

  const getVilles = (e) => {
    if (e?.target?.value?.length === 5) {
      const URL = import.meta.env.VITE_API_URL_AS;
      axiosInstance
        .post(`${URL}/groupe_villes/get_villes_by_code_postal`, {
          postal_code: e.target.value,
        })
        .then((res) => {
          let ville_names = [];
          res.data.villes.forEach((ville) => {
            ville_names.push({
              value: ville?.nom_comm,
              label: ville?.nom_comm,
            });
          });
          setVilles(ville_names);
          form.setFieldsValue({
            ...form.getFieldsValue(),
            ville: form.getFieldsValue()["ville"]
              ? ville_names?.find(
                  (elem) => elem?.value === form.getFieldsValue()["ville"]
                )
                ? form.getFieldsValue()["ville"]
                : ville_names[0]?.value
              : ville_names[0]?.value,
          });
        })
        .catch((err) => {
          setVilles([]);
          form.setFieldsValue({
            ...form.getFieldsValue(),
            ville: "",
          });
        });
    } else {
      setVilles([]);
      form.setFieldsValue({
        ...form.getFieldsValue(),
        ville: "",
      });
    }
  };

  useEffect(() => {
    form.setFieldValue("NUM_SIRET_1", globalData?.NUM_SIRET_1);
    setIsRequired(form.getFieldsValue()["encours_creations"]);
    if (form.getFieldsValue()["code_postal"]) {
      const e = {
        target: {
          value: form.getFieldsValue()["code_postal"],
        },
      };
      getVilles(e);
    }
    if (form.getFieldsValue()["_code_postal"]) {
      get_villes_by_code_postal(form.getFieldsValue()["_code_postal"]);
    }
    if (form.getFieldsValue()["code_naf"]) {
      get_code_activities(form.getFieldsValue()["code_naf"]);
    }
  }, []);

  const [isRequired, setIsRequired] = useState(false);

  return (
    <div>
      <FormHeader title="Informations principales" number="1" />
      <div className={classes.formContainer}>
        {/* ************ Section Sociéte ************** */}
        <Row
          gutter={24}
          className={classes.rowContainer}
          justify={"space-between"}
        >
          <Col lg={7} md={12} xs={24}>
            <SectionHeader icon={Societe} title={"L'Entreprise"} />

            <div style={{ display: "flex" }}>
              <div>
                Société en cours de création
                <br />( SIREN en cours d’attribution )
              </div>
              <Form.Item name={"encours_creations"}>
                <Switch
                  checked={isRequired}
                  checkedChildren="Oui"
                  unCheckedChildren="Non"
                  onChange={(e) => {
                    setIsRequired(e);
                  }}
                />
              </Form.Item>
            </div>
          </Col>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              label={
                <label className={classes.label}>Date d'effet souhaitée</label>
              }
              name="date_effet"
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir ce champ.",
                },
              ]}
            >
              <DatePicker
                size="large"
                value={form.getFieldsValue().date_effet}
                disabledDate={disabledDate}
                style={{ width: "166px", height: "100%" }}
                format={dateFormatList}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className={classes.rowContainer}>
          <Col lg={7} md={12} xs={24}>
            <Space>
              <Form.Item
                name="siren"
                rules={[
                  {
                    required: isRequired ? false : true,
                    whitespace: isRequired ? false : true,
                    message: "Veuillez remplir ce champ.",
                  },
                ]}
                label={<label className={classes.label}>SIREN</label>}
              >
                <Input
                  // disabled={disabled}
                  placeholder="880874243"
                  size="large"
                  maxLength={9}
                  onChange={onSearch}
                  style={{ padding: "11.5px 11.5px", width: "100%" }}
                  onKeyPress={(event) => {
                    if (event.key === " ") {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name="NUM_SIRET_1"
                rules={[
                  {
                    required: isRequired ? false : true,
                    whitespace: isRequired ? false : true,
                    message: "Veuillez remplir ce champ.",
                  },
                ]}
                label={<label className={classes.label}>SIRET</label>}
              >
                <AutoComplete
                  style={{
                    width: "100%",
                    padding: "11.5px",
                    marginBottom: "9px",
                  }}
                  size="large"
                  options={siretOptions}
                  onChange={(e) => {
                    fillSiretInput(e);
                  }}
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .includes(inputValue.toUpperCase())
                  }
                  placeholder="88087424300020"
                >
                  <Input
                    size="large"
                    onKeyPress={(event) => {
                      if (event.which < 48 || event.which > 57) {
                        event.preventDefault();
                      }
                    }}
                    style={{
                      width: "100%",
                      height: "100%",
                      padding: "14px",
                    }}
                  />
                </AutoComplete>
              </Form.Item>
            </Space>
          </Col>
          {/* <Col lg={7} md={12} xs={24}>
            <Form.Item
              name="NUM_SIRET_1"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Veuillez remplir ce champ.",
                },
              ]}
              label={<label className={classes.label}>SIRET</label>}
            >
              <AutoComplete
                size="large"
                options={siretOptions}
                onChange={(e) => {
                  fillSiretInput(e);
                }}
                filterOption={(inputValue, option) =>
                  option.value.toUpperCase().includes(inputValue.toUpperCase())
                }
              >
                <Input
                  size="large"
                  onKeyPress={(event) => {
                    if (event.which < 48 || event.which > 57) {
                      event.preventDefault();
                    }
                  }}
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: "12px 10px",
                  }}
                />
              </AutoComplete>
            </Form.Item>
          </Col> */}
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              name="raison_sociale"
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir ce champ.",
                },
              ]}
              label={<label className={classes.label}>Raison sociale</label>}
            >
              <Input
                size="large"
                style={{ padding: "10px 10px" }}
                placeholder={"Exp: X12-89"}
              />
            </Form.Item>
          </Col>
          {/* <Col lg={7} md={12} xs={24}>
            <Form.Item
              name="rcs"
              label={<label className={classes.label}>RCS</label>}
            >
              <Input
                size="large"
                placeholder={"Exp: 1245jk"}
                style={{ padding: "10px 10px" }}
              />
            </Form.Item>
          </Col> */}
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              label={<label className={classes.label}>Forme Juridique</label>}
              name="form_juridique"
            >
              <Select
                size="large"
                options={formeJuridique}
                onSelect={(e) => {
                  form.setFieldsValue({
                    ...form.getFieldsValue(),
                    form_juridique: e,
                  });
                  setGlobalData({
                    ...globalData,
                    form_juridique: e,
                  });
                }}
                placeholder={"Forme Juridique"}
                style={{ padding: "10px 10px" }}
              />
            </Form.Item>
          </Col>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              label={<label className={classes.label}>Date de création</label>}
              name="date_creation"
            >
              <DatePicker
                size="large"
                style={{ width: "100%", height: "100%", padding: "10px 10px" }}
                format={dateFormatList}
              />
            </Form.Item>
          </Col>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              label={<label className={classes.label}>Représentée par</label>}
              name="representant"
            >
              <Input
                size="large"
                placeholder={"Nom & Prénom"}
                style={{ padding: "10px 10px" }}
              />
            </Form.Item>
          </Col>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              label={<label className={classes.label}>En qualité de</label>}
              name="en_qualite_de"
            >
              <Select
                size="large"
                style={{ padding: "10px 10px" }}
                placeholder="Exp : Président"
              >
                <Select.Option value="Président">Président</Select.Option>
                <Select.Option value="Directeur général">
                  Directeur général
                </Select.Option>
                <Select.Option value="Directeur général délégué">
                  Directeur général délégué
                </Select.Option>
                <Select.Option value="Gérant">Gérant</Select.Option>
                <Select.Option value="Mandataire social">
                  Mandataire social
                </Select.Option>
                <Select.Option value="Responsable légal">
                  Responsable légal
                </Select.Option>
                <Select.Option value="Entrepreneur individuel">
                  Entrepreneur individuel
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {/* ************ END of Section Sociéte ************** */}

        {/* ************ Section Activité ************** */}
        <Row gutter={24} className={classes.rowContainer}>
          <Col lg={7} md={12} xs={24}>
            <SectionHeader icon={BreifCase} title={"Activité"} />
          </Col>
        </Row>
        <Row gutter={24} className={classes.rowContainer}>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              label={<label className={classes.label}>Code NAF</label>}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Veuillez remplir ce champ.",
                },
              ]}
              name="code_naf"
            >
              <Input
                placeholder="Exp: 2351Z"
                size="large"
                style={{ padding: "10px 10px" }}
                onBlur={(e) => {
                  get_code_activities(e.target.value.toUpperCase());
                  get_garanties_autorise();
                }}
                onChange={get_garanties_autorise}
              />
            </Form.Item>
          </Col>
          <Col lg={7}>
            <Form.Item
              name="label_naf"
              label={<label className={classes.label}>Libellé code NAF</label>}
            >
              <Input
                disabled
                size="large"
                value={labelNaf}
                style={{ padding: "10px 10px" }}
                placeholder="Libelle code NAF"
              />
            </Form.Item>
          </Col>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              label={
                <label className={classes.label}>
                  Chiffre d'affaires annuel HT
                </label>
              }
              name="chiffre_affaire"
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir ce champ.",
                },
                () => ({
                  validator(_, value) {
                    if (10000 <= value && value <= 3000000) {
                      return Promise.resolve();
                    }
                    if (value) {
                      return Promise.reject(
                        new Error(`Veuillez vérifier ce champ.`)
                      );
                    } else {
                      return Promise.reject(new Error(``));
                    }
                  },
                }),
              ]}
            >
              <InputNumber
                controls={false}
                size="large"
                placeholder={"Exp: 12 000"}
                onKeyPress={(event) => {
                  if (event.which < 48 || event.which > 57) {
                    event.preventDefault();
                  }
                }}
                addonAfter={"€"}
                style={{ width: "100%", height: "100%" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                }
                parser={(value) => value.replace(/\$\s?|( )/g, "")}
                onChange={debounce(get_garanties_autorise, 500)}
              />
            </Form.Item>
            <p
              className={classes.mutedText}
              style={{ marginTop: "-23px", fontSize: 11 }}
            >
              Le chiffre d'affaires doit être compris entre 10 000 € et 3 000
              000 €.
            </p>
          </Col>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              label={
                <label className={classes.label}>Activité principale</label>
              }
              name="code_activite_principale"
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir ce champ.",
                },
              ]}
            >
              <Select
                placeholder="Code activité principale"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="large"
                style={{
                  width: "100%",
                }}
                options={activities}
                onSelect={(e) => {
                  const val = activities.find((ac) => ac.value === e);
                  form.setFieldsValue({
                    ...form.getFieldsValue(),
                    label_activite_principale: val.label,
                  });
                  setGlobalData({
                    ...globalData,
                    label_activite_principale: val.label,
                  });
                  get_garanties_autorise();
                  /*  const temp = activities.filter((ac) => ac.value !== e);
                  setActivitiesSecondaire(temp); */
                }}
              />
            </Form.Item>
          </Col>
          {/* <Col
            lg={7}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
            }}
          >
            <p
              style={{
                marginBottom: "23px",
                color: "#0B68FA",
                fontWeight: 600,
              }}
            >
              Activité secondaire si celle-ci représente plus de 30% du CA.
            </p>
          </Col> */}

          <Col lg={7} md={12} xs={24}>
            <Form.Item
              name="code_activite_secondaire"
              label={
                <label className={classes.label}>Activité secondaire</label>
              }
            >
              <Select
                placeholder="Activité secondaire"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="large"
                style={{
                  width: "100%",
                }}
                options={activitiesSecondaire}
                onSelect={(e) => {
                  const val = activitiesSecondaire.find((ac) => ac.value === e);
                  form.setFieldsValue({
                    ...form.getFieldsValue(),
                    label_activite_secondaire: val.label,
                  });
                  setGlobalData({
                    ...globalData,
                    label_activite_secondaire: val.label,
                  });
                  get_garanties_autorise();
                }}
                suffixIcon={
                  <CloseOutlined
                    onClick={() =>
                      form.setFieldsValue({
                        code_activite_secondaire: undefined,
                      })
                    }
                  />
                }
              />
            </Form.Item>
            <p
              className={classes.mutedText}
              style={{ marginTop: "-15px", fontSize: 11 }}
            >
              Indiquez l'activité secondaire si celle-ci représente plus de 30%
              du CA.
            </p>
          </Col>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              label={
                <label className={classes.label}>
                  Code NAF activité facultative
                </label>
              }
              name="code_act_fact"
            >
              <Input
                size="large"
                placeholder={"Exp: 45896"}
                style={{ padding: "10px 10px" }}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* ************ END of Section Activité ************** */}

        {/* ************ Section Adresse ************** */}
        <Row gutter={24} className={classes.rowContainer}>
          <Col lg={7} md={12} xs={24}>
            <SectionHeader icon={Location} title={"Le Local"} />
          </Col>
        </Row>
        <Row gutter={24} className={classes.rowContainer}>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              label={
                <label className={classes.label}>
                  Adresse du local{" "}
                  <Tooltip
                    title={
                      "Nous n’acceptons pas les locaux  situés en Corse et dans les DROM"
                    }
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </label>
              }
              name="adresse_siege"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Veuillez remplir ce champ.",
                },
              ]}
            >
              <Input
                placeholder="Exp: 5 rue les oranges"
                size="large"
                style={{ padding: "10px 10px" }}
              />
            </Form.Item>
          </Col>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              label={
                <label className={classes.label}>Complément d'adresse</label>
              }
              name="complement_adresse"
            >
              <Input
                size="large"
                style={{ padding: "10px 10px" }}
                placeholder={"Exp: App 5 Block B"}
              />
            </Form.Item>
          </Col>
          <Col lg={7} md={12} xs={24} className={classes.hiddenBlock}></Col>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              name="code_postal"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Veuillez remplir ce champ.",
                },
                {
                  pattern: /^[\d]{5,5}$/,
                  message: "Veuillez vérifier ce champ.",
                },
              ]}
              label={<label className={classes.label}>Code postal</label>}
            >
              <Input
                size="large"
                maxLength={5}
                onChange={(e) => {
                  form.setFieldsValue({
                    code_postal: e.target.value.replace(/[^0-9]/g, ""),
                  });
                }}
                placeholder={"Exp: 82000"}
                style={{ padding: "10px 10px" }}
                onBlur={getVilles}
              />
            </Form.Item>
          </Col>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              name="ville"
              label={
                <label className={classes.label} name="ville">
                  Ville
                </label>
              }
            >
              <Select
                placeholder="Ville"
                size="large"
                style={{
                  width: "100%",
                  marginBottom: 20,
                }}
                options={villes}
              />
            </Form.Item>
          </Col>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              name="Pays"
              value={form["Pays"]}
              label={<label className={classes.label}>Pays</label>}
            >
              <Input size="large" disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} className={classes.rowContainer}>
          <Col lg={7} md={12} xs={24}>
            <SectionHeader icon={Souscripteur} title={"Représentant légal"} />
          </Col>
        </Row>
        <Row gutter={24} className={classes.rowContainer}>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              name="nom"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Veuillez remplir ce champ.",
                },
                // {
                //   pattern: new RegExp(
                //     /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/i
                //   ),
                //   message: "Veuillez vérifier ce champ",
                // },
              ]}
              label={<label className={classes.label}>Nom</label>}
            >
              <Input
                placeholder="Nom"
                size="large"
                style={{ padding: "10px 10px" }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(
                    /[^A-Za-zÀ-ÖØ-öø-ÿ’'-\s]/g,
                    ""
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              name="prenom"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Veuillez remplir ce champ.",
                },
              ]}
              label={<label className={classes.label}>Prénom</label>}
            >
              <Input
                placeholder="Prénom"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(
                    /[^A-Za-zÀ-ÖØ-öø-ÿ’'-\s]/g,
                    ""
                  );
                }}
                size="large"
                style={{ padding: "10px 10px" }}
              />
            </Form.Item>
          </Col>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              name="_adresse"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Veuillez remplir ce champ.",
                },
              ]}
              label={<label className={classes.label}>Adresse</label>}
            >
              <Input
                placeholder="Adresse"
                size="large"
                style={{ padding: "10px 10px" }}
              />
            </Form.Item>
          </Col>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              name="_code_postal"
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir ce champ.",
                },
                {
                  pattern: /^[\d]{5,5}$/,
                  message: "Le code postale doit être au moins 5 chiffres.",
                },
              ]}
              label={<label className={classes.label}>Code postal</label>}
            >
              <Input
                placeholder="Code postal"
                maxLength={5}
                size="large"
                style={{ padding: "10px 10px", width: "100%" }}
                onChange={(e) => {
                  form.setFieldsValue({
                    _code_postal: e.target.value.replace(/[^0-9]/g, ""),
                  });
                }}
                onBlur={(e) => {
                  get_villes_by_code_postal(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              name="_ville"
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir ce champ.",
                },
              ]}
              label={<label className={classes.label}>Ville</label>}
            >
              <Select
                placeholder="Ville"
                size="large"
                style={{
                  width: "100%",
                  marginBottom: 5,
                }}
                options={_villes}
              />
            </Form.Item>
          </Col>
          <Col lg={7} md={12} xs={24}>
            <Form.Item
              name="souscripteur_email"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "",
                },
                {
                  validator: (_, value) => {
                    const emptyValue = value ? value.replace(/\s/g, "") : "";
                    if (!emptyValue) {
                      return Promise.reject(
                        new Error("Veuillez remplir ce champ.")
                      );
                    }
                    if (value) {
                      let emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
                      if (!validRegex(value, emailReg)) {
                        return Promise.reject(
                          new Error("Veuillez vérifier ce champ.")
                        );
                      }
                    }
                    return Promise.resolve();
                  },
                },

                // {
                //   pattern: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
                //   message: "Veuillez vérifier ce champ.",
                // },
              ]}
              label={<label className={classes.label}>Email</label>}
            >
              <Input
                placeholder="email@email.com"
                size="large"
                style={{ padding: "10px 10px" }}
                onChange={(e) => {
                  form.setFieldsValue({
                    souscripteur_email: e.target.value.replace(/\s/g, ""),
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={7} md={12} xs={24}>
            <ConfigProvider locale={frFR}>
              <Form.Item
                name="formatedPhone"
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: (_, { valid }) => {
                      // if (valid(true)) return Promise.resolve(); // strict validation
                      if (valid()) return Promise.resolve(); // non-strict validation
                      return Promise.reject(
                        "Veuillez entrer un numéro de téléphone valide."
                      );
                    },
                  },

                  // {
                  //   pattern: new RegExp(/^0[1-9]\d{8}$/),
                  //   message: "Veuillez entrer un numéro de téléphone valide.",
                  // },

                  // {
                  //   validator: (_, value) => {
                  //     // let phoneREg = /^0?[67]\d{8}$/;
                  //     const emptyValue = value ? value.replace(/\s/g, "") : "";
                  //     if (!emptyValue) {
                  //       return Promise.reject(
                  //         new Error("Veuillez remplir ce champ.")
                  //       );
                  //     }

                  //     // if (!validRegex(value, phoneREg)) {
                  //     //   return Promise.reject(
                  //     //     new Error("Veuillez vérifier ce champ.")
                  //     //   );
                  //     // }
                  //     return Promise.resolve();
                  //   },
                  // },

                  // {
                  //   pattern: new RegExp(/^(?!0+$|123456789$)(?!\d{11,})\d+$/),
                  //   message: "Veuillez vérifier ce champ.",
                  // },
                ]}
                label={
                  <label className={classes.label}>Numéro de téléphone</label>
                }
              >
                {/* <Input
                size="large"
                onChange={(e) => {
                  form.setFieldsValue({
                    téléphone: e.target.value.replace(/[^0-9 -]/g, ""),
                  });
                }}
                controls={false}
                addonBefore={<PhoneOutlined style={{ color: "black" }} />}
                prefix="+33"
                style={{ width: "100%" }}
              /> */}

                <PhoneInput
                  country={"fr"}
                  onBlur={(e) => {
                    // Remove any existing formatting
                    // let formattedValue = e.target.value.replace(/\D/g, "");

                    // // Apply custom formatting: +216 55 512 924
                    // if (formattedValue.length > 3) {
                    //   formattedValue = formattedValue.replace(
                    //     /(.{3})(.{2})(.{3})(.{3})/,
                    //     "+$1 $2 $3 $4"
                    //   );
                    // }
                    setGlobalData({
                      ...globalData,
                      téléphone: e.target.value.replace(/\s/g, ""),
                    });
                    form.setFieldsValue({
                      téléphone: e.target.value.replace(/\s/g, ""),
                    });
                  }}
                  enableSearch
                  disableParenthesesù
                  placeholder="+33745432258"
                />
              </Form.Item>
            </ConfigProvider>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default InfoPrincipale;
