import React from "react";
import classes from "./FormHeader.module.css";
import ArrowLeft from "../../assets/ArrowLeft.png";
import { Divider } from "antd";

export const FormHeader = (props) => {
  const { title, number, prev } = props;

  return (
    <div className={classes.container}>
      <div className={classes.previous}>
        {prev && (
          <button onClick={prev} id="fleche_retour">
            <img src={ArrowLeft} alt="Arrow Left" />
          </button>
        )}
      </div>
      <Divider orientation="center">
        <div className={classes.titleHolder}>
          <div className={classes.number}>
            <span>{number}</span>
          </div>
          <span className={classes.title} style={{ textAlign: "center" }}>
            {title}
          </span>
        </div>
      </Divider>
    </div>
  );
};
