import { Checkbox, Col, Form, InputNumber, Radio, Row, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useContext } from "react";
import { FormHeader } from "../FormHeader/FormHeader";
import classes from "./InfoComplementaire.module.css";
import GlobalContext from "../../contexts/GlobalContext";
import RequiredWaranty from "../Waranties/RequiredWaranty";
import OptionalWaranty from "../Waranties/OptionalWaranty";
import QueueAnim from "rc-queue-anim";
import TextyAnim from "rc-texty";
import PopoverInfo from "../DevoirDeConseil/PopoverInfo";
import debounce from "debounce";
import { InfoCircleOutlined } from "@ant-design/icons";

const OptionDisplay = ({
  warantyVol,
  setWarantyVol,
  globalData,
  setGlobalData,
}) => {
  return (
    <>
      <Form.Item
        name={
          "Le local est-il à minima, muni des moyens de protection suivants ?"
        }
        valuePropName="checked"
      >
        <Checkbox
          value={
            globalData[
              "Le local est-il à minima, muni des moyens de protection suivants ?"
            ]
          }
          onChange={(e) => {
            setGlobalData({
              ...globalData,
              "Le local est-il à minima, muni des moyens de protection suivants ?":
                e.target.checked,
              garanties: {
                ...globalData?.garanties,
                "Garantie Vol": e.target.checked,
              },
            });
          }}
        >
          Le local est-il à minima, muni des moyens de protection suivants ?{" "}
          <span id="moyen_protection_tooltip">
            <PopoverInfo />
          </span>
        </Checkbox>
      </Form.Item>
      <div style={{ marginTop: "-25px" }}>
        <span
          style={{
            fontSize: 13,
            color: "#0B68FA",
            fontStyle: "italic",
          }}
        >
          En cas d’absence de moyens de protections, la garantie vol ne sera pas
          acquise.
        </span>{" "}
      </div>
      <Form.Item
        name={"Détention d'espèces ou objets de valeur de plus de 5 000 Euro ?"}
        valuePropName="checked"
      >
        <Checkbox value={true}>
          Si détention d'espèces ou objets de valeur, je certifie sur l'honneur
          détenir un coffre fort
        </Checkbox>
      </Form.Item>
    </>
  );
};

function InfoComplementaire(props) {
  const { axiosInstance } = useContext(GlobalContext);
  const { prev, form } = props;
  const {
    globalData,
    setGlobalData,
    value,
    setValue,
    garantiesAutorise,
    warantyVol,
    setWarantyVol,
  } = useContext(GlobalContext);
  const [requiredWaranties, setRequiredWaranties] = React.useState({});
  const [optionWaranties, setOptionWaranties] = React.useState({});
  const [capitalprotege, setCapitalprotege] = React.useState(
    form.getFieldsValue()?.capital_protege
  );

  useEffect(() => {
    if (globalData?.QUALITE_OCCUPANT_2) {
      setValue(globalData?.QUALITE_OCCUPANT_2);
    }
  }, []);

  const onChange = (e) => {
    setValue(e.target.value);
    setGlobalData({
      ...globalData,
      QUALITE_OCCUPANT_2: e.target.value,
    });
  };

  const get_capital_protege_total = () => {
    const valeur_metre_carre = form.getFieldsValue().valeur_metre_carre;
    const surface_locaux = form.getFieldsValue().surface_locaux;
    const contenu_pro = form.getFieldsValue().contenu_pro;

    if (
      contenu_pro !== "" &&
      surface_locaux !== "" &&
      valeur_metre_carre !== "" &&
      500 <= contenu_pro &&
      contenu_pro <= 1000000 &&
      10 <= surface_locaux &&
      surface_locaux <= 3000 &&
      500 <= valeur_metre_carre &&
      valeur_metre_carre <= 3000
    ) {
      const URL = import.meta.env.VITE_API_URL_AS;
      axiosInstance
        .post(`${URL}/taux_fidelidade/get_capital_protege_total`, {
          surface_locaux: parseFloat(surface_locaux),
          valeur_metre_carre: parseFloat(valeur_metre_carre),
          contenu_pro: parseFloat(contenu_pro),
        })
        .then((res) => {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            capital_protege: res?.data?.get_capital_protege_total.toFixed(2),
          });
          setGlobalData({
            ...globalData,
            capital_protege: res?.data?.get_capital_protege_total.toFixed(2),
          });
          setCapitalprotege(res?.data?.get_capital_protege_total.toFixed(2));
          if (
            Math.min(
              res?.data?.get_capital_protege_total.toFixed(2) * 0.5,
              form.getFieldsValue()["contenu_pro"]
            ) == 500
          ) {
            form.setFieldsValue({
              ...form.getFieldsValue(),
              capitaux_machine: 500,
            });
            setGlobalData({
              ...globalData,
              capitaux_machine: 500,
            });
          }
        });
    }
  };

  useEffect(() => {
    if (
      globalData?.contenu_pro !== "" &&
      globalData?.surface_locaux !== "" &&
      globalData?.valeur_metre_carre !== ""
    ) {
      get_capital_protege_total();
    }
  }, []);

  const qualiate_occupant = [
    "Locataire",
    "Propriétaire Occupant",
    "Copropriétaire",
    "Usufruitier",
    "Nu-propriétaire",
    "Assurance pour compte",
  ];

  useEffect(() => {
    if (capitalprotege) {
      const requiredWarantiesTemp = {
        "Garantie DDE": {
          available: garantiesAutorise["Garantie DDE"],
          inputVisible: true,
          title: "Dégât des Eaux",
          inputOptions: {
            name: "capitaux_Degat_des_eaux",
            form: form,
            min: Math.round(capitalprotege * 0.05),
            max:
              Math.round(capitalprotege * 0.25) < 750000
                ? Math.round(capitalprotege * 0.25)
                : 750000,
            label: null,
            tip: (
              <>
                Entre{" "}
                {capitalprotege && capitalprotege != 0
                  ? Math.round(capitalprotege * 0.05).toLocaleString("fr-FR", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })
                  : ""}
                {" € "}
                et{" "}
                {capitalprotege && capitalprotege != 0
                  ? Math.round(capitalprotege * 0.25) < 750000
                    ? Math.round(capitalprotege * 0.25).toLocaleString(
                        "fr-FR",
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        }
                      ) + " € "
                    : "750 000 €"
                  : ""}{" "}
                {Math.round(capitalprotege * 0.25) < 750000
                  ? "(25%) du capital total à protéger"
                  : ""}
              </>
            ),
            placeHolder: "Entre (5%) et (25%) du capital total à protéger",
          },
          description:
            "Suite à ruptures, fuites, infiltrations et débordements pour les dommages au contenu, les frais de recherche et de réparation, les frais de surconsommation d'eau et tout évènement entraînant des dommages d'eau incombant à un tiers identifié.",
        },
        "Garantie BdG": {
          title: "Bris de Glace",
          available: garantiesAutorise["Garantie BdG"],
          inputVisible: true,
          inputOptions: {
            name: "capitaux_bris_de_glace",
            form: form,
            min: 1000,
            max: 30000,
            label: null,
            tip: "Valeurs comprises entre 1 000 € et 30 000 €",
            placeHolder: "Min 1 000 € / Max 30 000 €",
          },
          description:
            "Bris de biens (vitrines, enseignes, plaques, etc), marchandise détériorée par le bris de produits et aménagements en verre, vol de marchandises exposées dans les vitrines suite à leur bris, frais de clôture et de gardiennage.",
        },
        Incendie: {
          title: "Incendie",
          available: garantiesAutorise["Incendie"],
          inputVisible: false,
          description:
            "Dommages aux biens meubles et immeubles (dont les espèces, titres, valeurs, billets et monnaies) liés à l'incendie, au dommage de fumée, la fumée sans incendie, la foudre, l'implosion et l'explosion.",
        },
        TGNA: {
          title: "Evènements Climatiques",
          available: garantiesAutorise["TGNA"],
          inputVisible: false,
          description:
            "Couvre les dommages contenu professionnel (dont espèces, titres, valeurs, billets et monnaies) liés aux évenements climatiques de tempête, de grêle, de neige et avalanche.",
        },
        CatNat: {
          title: "Catastrophes Naturelles",
          available: garantiesAutorise["CatNat"],
          inputVisible: false,
          description:
            "Cette garantie couvre les dommages causés à votre propriété en cas de catastrophe naturelle telle qu'un incendie, une inondation, un séisme, etc.",
        },
        "RC exploitation": {
          title: "RC Exploitation",
          available: garantiesAutorise["RC exploitation"],
          inputVisible: false,
          description:
            "L'assurance responsabilité civile exploitation plus permet d'être couvert en cas de dommages corporels, matériels ou immatériels causés à des tiers (clients, fournisseurs...).",
        },
        "Attentats et actes de terrorisme": {
          title: "Attentats et actes de terrorisme",
          available: true,
          inputVisible: false,
          description: "Dommages matériels directs et immatériels consécutifs",
        },
        "Défense et recours": {
          title: "Défense et recours",
          available: true,
          inputVisible: false,
          description:
            "Défense de l’Assuré en cas d’action mettant en cause une responsabilité garantie par le contrat",
        },
        "Garantie Assistance": {
          title: "Assistance",
          available: false,
          inputVisible: false,
          description:
            "Cette garantie vous offre une aide 24 heures sur 24, 7 jours sur 7 en cas de besoin, tels que le remorquage de votre véhicule, l'hébergement en cas d'urgence, etc.",
        },
        "RC professionnelle": {
          title: "RC Professionnelle",
          available: garantiesAutorise["RC professionnelle"],
          inputVisible: false,
          description:
            "La responsabilité civile est une assurance qui couvre les dommages causés à autrui, par votre entreprise, en lien direct avec l'exercice de l'activité professionnelle, mais également, une fois la prestation ou la vente effectuée.",
        },
      };
      const optionWarantiesTemp = {
        "Garantie Vol": {
          title: "Vol",
          available: garantiesAutorise["Garantie Vol"],
          switchVisible: true,
          inputVisible:
            warantyVol &&
            globalData[
              "Le local est-il à minima, muni des moyens de protection suivants ?"
            ],
          options: (
            <OptionDisplay
              warantyVol={warantyVol}
              setWarantyVol={setWarantyVol}
              globalData={globalData}
              setGlobalData={setGlobalData}
            />
          ),
          switchName: "Garantie Vol",
          switchDefaultValue: warantyVol,
          inputOptions: {
            name: "capitaux_vol",
            form: form,
            min: 500,
            max: Math.min(capitalprotege * 0.5, 150000),
            label: null,
            tip: (
              <>
                Entre 500€ et{" "}
                {capitalprotege
                  ? `${Math.min(capitalprotege * 0.5, 150000).toLocaleString(
                      "fr-FR",
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      }
                    )} €`
                  : ""}{" "}
                (50% du capital plafonné à 150 000 €).
              </>
            ),
            placeHolder: "Capitaux vol",
          },
          description:
            "Vol, tentative de vol, vandalisme sur les biens mobiliers assurés et garantis (dont espèces, titres, valeurs, billets et monnaies), détériorations immobilières et coût du remplacement des serrures.",
        },
        "Garantie Bris de Machines": {
          title: "Bris de machines",
          available: garantiesAutorise["Garantie Bris de Machines"],
          switchVisible: true,
          inputVisible: true,
          switchName: "Garantie Bris de Machines",
          switchDefaultValue:
            globalData?.garanties["Garantie Bris de Machines"],
          inputOptions: {
            name: "capitaux_machine",
            form: form,
            min: 500,
            max:
              Math.min(
                capitalprotege * 0.5,
                form.getFieldsValue()["contenu_pro"]
              ) !== 500
                ? Math.min(
                    capitalprotege * 0.5,
                    form.getFieldsValue()["contenu_pro"]
                  ) > 200000
                  ? 200000
                  : Math.min(
                      capitalprotege * 0.5,
                      form.getFieldsValue()["contenu_pro"]
                    )
                : 500,
            label: null,
            tip:
              Math.min(
                capitalprotege * 0.5,
                form.getFieldsValue()["contenu_pro"]
              ) !== 500
                ? capitalprotege && (
                    <p>
                      Entre 500 € et{" "}
                      {capitalprotege
                        ? `${
                            Math.min(
                              capitalprotege * 0.5,
                              form.getFieldsValue()["contenu_pro"]
                            ) > 200000
                              ? "200 000"
                              : Math.min(
                                  capitalprotege * 0.5,
                                  form.getFieldsValue()["contenu_pro"]
                                ).toLocaleString("fr-FR", {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2,
                                })
                          } €`
                        : ""}{" "}
                      (50% du capital plafonné à 200 000 €)
                    </p>
                  )
                : capitalprotege && (
                    <p>Capitaux Bris de machine doit être 500 €</p>
                  ),
            placeHolder: "Min 500 €",
          },
          description:
            "Bris de machines, matériaux et équipements informatiques ainsi que les frais annexes de reconstitution d'archives.",
        },
        "Dommages Electriques": {
          title: "Dommages Electriques",
          available: garantiesAutorise["Dommages Electriques"],
          switchVisible: true,
          inputVisible: true,
          switchName: "Dommages Electriques",
          switchDefaultValue: globalData?.garanties["Dommages Electriques"],
          inputOptions: {
            name: "capitaux_dommage",
            form: form,
            min: 500,
            max: Math.round(capitalprotege * 0.25),
            label: null,
            tip: (
              <>
                {" "}
                Couverture conseillée 25% du capital à protéger (
                {capitalprotege
                  ? Math.round(capitalprotege * 0.25).toLocaleString("fr-FR", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    }) + "€"
                  : ""}{" "}
                )
              </>
            ),
            placeHolder: "Entre 500 € et 25% du capital total à protéger",
          },
          description:
            "Dommages à l'installation électrique des bâtiments assurés, aux appareils électriques, électroniques et informatiques (et accessoires) contenus dans les bâtiments assurés.",
        },

        // SECOND HALF
        "Marchandises Matériels Transportés": {
          title: "Marchandises transportées",
          available: garantiesAutorise["Marchandises Matériels Transportés"],
          switchVisible: true,
          inputVisible: true,
          switchName: "Marchandises Matériels Transportés",
          switchDefaultValue:
            globalData?.garanties["Marchandises Matériels Transportés"],
          inputOptions: {
            name: "capitaux_transport",
            form: form,
            min: 500,
            max: 50000,
            label: null,
            tip: "Capitaux marchandises transportées compris entre 500 € et 50 000 €",
            placeHolder: "Max 50 000",
          },
          description:
            "Dommage ou vol de marchandises et instruments professionnels transportés (dont salons, foires et manifestations).",
        },
        "Extension Extérieurs": {
          title: "Aménagements extérieurs",
          available: garantiesAutorise["Extension Extérieurs"],
          switchVisible: true,
          inputVisible: true,
          switchName: "Extension Extérieurs",
          switchDefaultValue: globalData?.garanties["Extension Extérieurs"],
          inputOptions: {
            name: "capitaux_extension",
            form: form,
            min: 500,
            max: 10000,
            label: null,
            tip: "Capitaux aménagements extérieurs compris entre 500 € et 10 000 €",
            placeHolder: "Max 10 000",
          },
          description:
            "Dommages aux terrains, voies d'accès, parkings, clôtures, haies végétales et arbres (dont dessouchage, tronçonnage et évacuation).",
        },
        "Marchandises en Frigo": {
          title: "Marchandises en Frigo",
          available: garantiesAutorise["Marchandises en Frigo"],
          switchVisible: true,
          inputVisible: true,
          switchName: "Marchandises en Frigo",
          switchDefaultValue: globalData?.garanties["Marchandises en Frigo"],
          inputOptions: {
            name: "capitaux_frigo",
            form: form,
            min: 500,
            max: 15000,
            label: null,
            tip: "Capitaux Marchandises frigo compris entre 500 € et 15 000 €",
            placeHolder: "Max 15 000",
          },
          description:
            "Perte des marchandises en installation frigorifiques dont frais annexes de sauvetage.",
        },
        "PE suite à Vol ou Vandalisme": {
          title: (
            <>
              Perte d'Exploitation <br /> suite à Vol et Vandalisme
            </>
          ),
          available: garantiesAutorise["PE suite à Vol ou Vandalisme"],
          switchVisible: true,
          switchName: "PE suite à Vol ou Vandalisme",
          switchDefaultValue:
            globalData?.garanties["PE suite à Vol ou Vandalisme"],
          description:
            "Elle couvre les pertes financières résultant de dommages causés à votre entreprise en raison d'un vol ou d'un acte de vandalisme.",
        },
        "Perte de Valeur du Fonds": {
          title: "Perte de Valeur du Fonds",
          available: garantiesAutorise["Perte de Valeur du Fonds"],
          switchVisible: true,
          switchName: "Perte de Valeur du Fonds",
          switchDefaultValue: globalData?.garanties["Perte de Valeur du Fonds"],
          description:
            "Cette assurance vous indemnise pour la perte de valeur de votre fonds de commerce en cas de dommages subis par votre entreprise.",
        },
        "PE suite à incendie ou DDE": {
          title: (
            <>
              Perte d'Exploitation <br /> suite à Incendie ou Dégat Des Eaux
            </>
          ),
          available: garantiesAutorise["PE suite à incendie ou DDE"],
          switchVisible: true,
          switchName: "PE suite à incendie ou DDE",
          switchDefaultValue:
            globalData?.garanties["PE suite à incendie ou DDE"],
          description:
            "Cette garantie vous indemnise pour les pertes financières causées par les dommages subis par votre entreprise en raison d'un incendie ou de dommages d'eau.",
        },
        "Protection Juridique": {
          title: "Protection Juridique",
          available: garantiesAutorise["Protection Juridique"],
          switchVisible: true,
          inputVisible: false,
          switchName: "Protection Juridique",
          switchDefaultValue: globalData?.garanties["Protection Juridique"],
          description:
            "Elle couvre les coûts liés à un litige découlant de votre activité professionnelle ou de votre entreprise, tels que les frais de justice et les frais de défense.",
        },
      };

      setRequiredWaranties(requiredWarantiesTemp);
      setOptionWaranties(optionWarantiesTemp);
    } else {
      setRequiredWaranties({});
      setOptionWaranties({});
    }
  }, [capitalprotege, garantiesAutorise, warantyVol, globalData]);

  return (
    <div className={classes.container}>
      <FormHeader title="Informations complémentaires" number="3" prev={prev} />
      <div className={classes.qualityBlock}>
        <h2 style={{ color: "#292371" }}>Qualité de l'occupant</h2>
        <Form.Item label="" name="qualiate_occupant">
          <Radio.Group onChange={onChange} value={value} defaultValue={value}>
            {qualiate_occupant.map((e, index) => (
              <Radio key={index} value={e} id={e}>
                {e}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </div>

      <Row gutter={24} className={classes.rowContainer}>
        <Col lg={7} md={12} xs={24}>
          <Form.Item
            label={
              <label className={classes.label}>
                Surface des locaux{" "}
                <Tooltip
                  title={
                    "Total pour chaque bâtiment de l’ensemble des superficies de plancher – épaisseur des murs comprise – des sous-sols, du rez-de-chaussée et de chacun des autres niveaux, ou à défaut la surface figurant au bail"
                  }
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </label>
            }
            name="surface_locaux"
            rules={[
              {
                required: true,
                message: "Veuillez remplir ce champ.",
              },
              () => ({
                validator(_, value) {
                  if (10 <= value && value <= 3000) {
                    return Promise.resolve();
                  } else if (value) {
                    return Promise.reject(
                      new Error("Veuillez vérifier ce champ.")
                    );
                  } else {
                    return Promise.reject(new Error());
                  }
                },
              }),
            ]}
          >
            <InputNumber
              controls={false}
              size="large"
              placeholder={"Min 10"}
              addonAfter={"m²"}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              }
              parser={(value) => value.replace(/\$\s?|( )/g, "")}
              style={{ padding: "10px 10px", width: "100%" }}
              onChange={debounce(get_capital_protege_total, 500)}
              onKeyPress={(event) => {
                if (event.key === "." || event.key === ",") {
                  event.preventDefault();
                } else if (event.which < 48 || event.which > 57) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
          <p className={classes.mutedText} style={{ marginTop: "-23px" }}>
            Surface comprise entre 10 m² et 3 000 m²
          </p>
        </Col>
        <Col lg={7} md={12} xs={24}>
          <Form.Item
            name="valeur_metre_carre"
            label={
              <label className={classes.label}>Valeur du mètre carré</label>
            }
            rules={[
              {
                required: true,
                message: "Veuillez remplir ce champ.",
              },
              () => ({
                validator(_, value) {
                  if (500 <= value && value <= 3000) {
                    return Promise.resolve();
                  } else if (value) {
                    return Promise.reject(
                      new Error("Veuillez vérifier ce champ.")
                    );
                  } else {
                    return Promise.reject(new Error());
                  }
                },
              }),
            ]}
          >
            <InputNumber
              controls={false}
              size="large"
              placeholder={"Min 500"}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              }
              parser={(value) => value.replace(/\$\s?|( )/g, "")}
              addonAfter={"€/m²"}
              style={{ padding: "10px 10px", width: "100%" }}
              onChange={debounce(get_capital_protege_total, 500)}
              onKeyPress={(event) => {
                if (event.key === "." || event.key === ",") {
                  event.preventDefault();
                } else if (event.which < 48 || event.which > 57) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
          <p className={classes.mutedText} style={{ marginTop: "-23px" }}>
            Valeur du mètre carré compris entre 500 €/m² et 3 000 €/m²
          </p>
        </Col>
        <Col lg={7} md={12} xs={24}>
          <Form.Item
            name="contenu_pro"
            label={
              <label className={classes.label}>
                Montant du contenu pro à assurer{" "}
                <Tooltip
                  title={
                    "Le matériel, mobilier personnel, les marchandises et biens confiés par les tiers dont vous avez la garde, espèces, titres et valeurs, archives."
                  }
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </label>
            }
            rules={[
              {
                required: true,
                message: "Veuillez remplir ce champ.",
              },
              () => ({
                validator(_, value) {
                  if (500 <= value && value <= 1000000) {
                    return Promise.resolve();
                  } else if (value) {
                    return Promise.reject(
                      new Error("Veuillez vérifier ce champ.")
                    );
                  } else {
                    return Promise.reject(new Error());
                  }
                },
              }),
            ]}
          >
            <InputNumber
              size="large"
              controls={false}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              }
              parser={(value) => value.replace(/\$\s?|( )/g, "")}
              placeholder={"Max 1 000 000"}
              addonAfter={"€"}
              style={{ padding: "10px 10px", width: "100%" }}
              onChange={debounce(get_capital_protege_total, 500)}
              onKeyPress={(event) => {
                if (event.key === "." || event.key === ",") {
                  event.preventDefault();
                } else if (event.which < 48 || event.which > 57) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
          <p className={classes.mutedText} style={{ marginTop: "-23px" }}>
            Matériel Professionnel utilisé pour les besoins de l'activité
            garantie - entre 500 € et 1 000 000 €
          </p>
        </Col>
        <Col lg={6} md={12} xs={24}></Col>
        <Col lg={10} md={12} xs={24} className={classes.highltedBlock}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <label
              className={classes.label}
              style={{ fontWeight: 600, marginTop: "-25px" }}
            >
              Le capital total à protéger
            </label>
            <Form.Item name="capital_protege">
              <InputNumber
                disabled
                size="large"
                controls={false}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                }
                parser={(value) => value.replace(/\$\s?|( )/g, "")}
                addonAfter={"€"}
                style={{
                  padding: "10px 10px",
                  textDecorationColor: "black",
                  width: "100%",
                }}
                onKeyPress={(event) => {
                  if (event.key === "." || event.key === ",") {
                    event.preventDefault();
                  } else if (event.which < 48 || event.which > 57) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </div>
          <br />
          <p
            className={classes.mutedText}
            style={{ marginTop: "-50px", fontSize: 11 }}
          >
            (superficie des locaux * valeur m2 ) + valeur du contenu pro à
            protéger
          </p>
          <h4>Visite de risque</h4>
          <ul>
            <li style={{ fontSize: 12 }}>
              Pas de visite de risque jusqu’à 50 000€ de capital contenu protégé
              et/ou si la surface des locaux {"<"} 50 m2
            </li>
            <li style={{ fontSize: 12 }}>
              Questionnaire de connaissance jusqu’à 100 000€ de capital contenu
              protégé
            </li>
            <li style={{ fontSize: 12 }}>
              Visite de risque si le capital contenu protégé est {">"} 100 000 €
            </li>
          </ul>
        </Col>
        <Col lg={6} md={12} xs={24}></Col>
      </Row>

      <div style={{ width: "75%" }}>
        {Object.keys(requiredWaranties)?.length > 0 && (
          <h2 key={"textGOH2"} style={{ textAlign: "center" }}>
            <TextyAnim key={"textGO"}>Garanties Obligatoires</TextyAnim>
          </h2>
        )}
        <QueueAnim type={"bottom"} key={"queueGO"} component={Row}>
          {Object.keys(requiredWaranties).map((key) => (
            <Col lg={12} key={key} style={{ padding: "2px 16px 32px 16px" }}>
              <RequiredWaranty key={key} {...requiredWaranties[key]} />
            </Col>
          ))}
        </QueueAnim>
        {Object.keys(optionWaranties)?.length > 0 && (
          <h2 key={"textGOOH2"} style={{ textAlign: "center" }}>
            <TextyAnim key={"textGOO"}>Garanties Optionnelles</TextyAnim>
          </h2>
        )}

        <QueueAnim type={"bottom"} key={"queueGOO"} component={Row}>
          {Object.keys(optionWaranties).map((key) => (
            <Col
              lg={12}
              key={"waranties-" + key}
              style={{ padding: "2px 16px 32px 16px" }}
            >
              <OptionalWaranty key={key} {...optionWaranties[key]} />
            </Col>
          ))}
          {/* <Col
            lg={12}
            key="waranties-1"
            style={{ padding: "2px 16px 32px 16px" }}
          >
            {Object.keys(optionWaranties)
              .slice(0, 5)
              .map((key) => (
                <OptionalWaranty key={key} {...optionWaranties[key]} />
              ))}
          </Col>
          <Col
            lg={12}
            key="waranties-2"
            style={{ padding: "2px 16px 32px 16px" }}
          >
            {Object.keys(optionWaranties)
              .slice(5, Object.keys(optionWaranties)?.length)
              .map((key) => (
                <OptionalWaranty key={key} {...optionWaranties[key]} />
              ))}
          </Col> */}
        </QueueAnim>
      </div>
    </div>
  );
}

export default InfoComplementaire;
