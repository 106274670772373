import React from "react";
import icon from "../assets/time_fly.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
function ExpirationPage() {
  const navigate = useNavigate();
  const partnership_id = new URLSearchParams(window.location.search).get(
    "partnership_id"
  );
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img alt="timeflys" height={250} width={250} src={icon} />
      <div
        style={{
          width: "60vw",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h4 style={{ textAlign: "center" }}>
          Nous espérons que vous allez bien. Nous tenions à vous informer que le
          devis d'assurance que nous vous avions précédemment fourni a expiré,
          étant donné que la période de validité a été dépassée !
        </h4>
        <Button
          style={{
            marginTop: "1.5rem",
            width: "20%",
            backgroundColor: "#cf1d11",
          }}
          size="large"
          type="primary"
          onClick={() => {
            navigate(`/?partnership_id=${partnership_id}`);
            window.location.reload();
          }}
        >
          {" "}
          Cliquer ici{" "}
        </Button>
      </div>
    </div>
  );
}

export default ExpirationPage;
