import { Tag } from "antd";
import React from "react";

const WarantyTag = ({ available }) => {
  return available ? (
    <Tag
      color="#E00000"
      style={{
        fontSize: 16,
        padding: "4px 16px 7px 16px",
        fontWeight: 600,
        borderRadius: "29px",
      }}
    >
      Acquise
    </Tag>
  ) : (
    <Tag
      color="white"
      style={{
        fontSize: 16,
        color: "#E00000",
        padding: "4px 16px 7px 16px",
        fontWeight: 600,
        borderRadius: "29px",
      }}
    >
      Exclue
    </Tag>
  );
};

export default WarantyTag;
