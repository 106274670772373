import React, { useState } from "react";
import { Button, Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import classes from "./PopoverInfo.module.css";

const PopoverInfo = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        type="primary"
        shape="circle"
        size="small"
        onClick={() => setOpen(true)}
      >
        <InfoCircleOutlined />
      </Button>
      <Modal
        footer={[
          <Button key="back" type="primary" onClick={() => setOpen(false)} id="close_modal_button">
            Fermer
          </Button>,
        ]}
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
      >
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead className={classes.tHead}>
              <tr>
                <th
                  className={classes.th}
                  style={{ width: "10%", borderRight: "2px solid white" }}
                >
                  Niveau Garantie
                </th>
                <th
                  className={classes.th}
                  style={{ width: "17%", borderRight: "2px solid white" }}
                >
                  Montant Plafond garantie vol
                </th>
                <th className={classes.th}>
                  Mesures de Prévention SOUS PEINE DE NON-GARANTIE, Vos locaux
                  professionnels doivent comporter au minimum les moyens de
                  protections suivants : CES NIVEAUX SONT CUMULATIFS
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  className={classes.firstColumn}
                  style={{ borderBottom: "2px dotted #c4645c" }}
                >
                  1
                </td>
                <td
                  className={classes.column}
                  style={{ borderBottom: "2px dotted #c4645c" }}
                >
                  {"<10 000 €"}
                </td>
                <td
                  className={classes.details}
                  style={{ borderBottom: "2px dotted #c4645c" }}
                >
                  <ul>
                    <li className={classes.li}>
                      Pour{" "}
                      <span className={classes.span}>les portes d’accès :</span>{" "}
                      2 systèmes de fermeture dont un moins de sûreté.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td
                  className={classes.firstColumn}
                  style={{ borderBottom: "2px dotted #c4645c" }}
                >
                  2
                </td>
                <td
                  className={classes.column}
                  style={{ borderBottom: "2px dotted #c4645c" }}
                >
                  {">10 000€ < 50 000€"}
                </td>
                <td
                  className={classes.details}
                  style={{ borderBottom: "2px dotted #c4645c" }}
                >
                  <ul>
                    <li className={classes.li}>
                      Pour{" "}
                      <span className={classes.span}>les portes vitrées</span>{" "}
                      (autre que les vitrines et devantures),
                      <span className={classes.span}>
                        soupiraux ou autres ouvertures accessibles :{" "}
                      </span>
                      des barreaux ou grilles métalliques fixés dans la
                      maçonnerie et espacés au maximum de
                      <br />
                      12 cm.
                    </li>
                    <li className={classes.li}>
                      Pour les vitrines ou devantures : rideaux ou grilles
                      métalliques ou glaces antieffraction.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td
                  className={classes.firstColumn}
                  style={{ borderBottom: "2px dotted #c4645c" }}
                >
                  3
                </td>
                <td
                  className={classes.column}
                  style={{ borderBottom: "2px dotted #c4645c" }}
                >
                  {">50 000€ < 150 000€"}
                </td>
                <td
                  className={classes.details}
                  style={{ borderBottom: "2px dotted #c4645c" }}
                >
                  <ul>
                    <li className={classes.li}>
                      Une{" "}
                      <span className={classes.span}>
                        installation d’alarme
                      </span>{" "}
                      figure parmi ces moyens de protection, elle doit être
                      enclenchée et vous vous engagez à respecter les
                      prescriptions suivantes
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td className={classes.firstColumn}>4</td>
                <td className={classes.column}>{"Option si valeur"}</td>
                <td className={classes.details}>
                  <ul>
                    <li className={classes.li}>
                      Un <span className={classes.span}>coffre-fort</span>
                    </li>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        Détention espèce ou objet de valeur-plafond limité à 8
                        000€
                      </span>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};
export default PopoverInfo;
